// modules
import React from 'react';
import { FaTimes } from 'react-icons/fa';

// styles
import {
  HeaderImgBox,
  HeaderImg,
  HiddenInput,
  HeaderText,
  ComponentDeleteButton
} from '../../../styles/home';

import { headerNoImagePath } from '../../../utils/assets.js.erb'

class HeaderImage extends React.Component {

  render() {
    const { src, handleChangeHeader, handleDeleteHeader } = this.props;
    return (
      <HeaderImgBox
        onClick={(event) => headerInput.click() }>
        {(() => {
          if (src) {
            return(
              <ComponentDeleteButton
                size='sm'
                data-param-name='header'
                onClick={handleDeleteHeader}>
                <FaTimes/>
              </ComponentDeleteButton>
            )
          }
        })()}
        <HeaderImg src={src || headerNoImagePath} />
        <HeaderText>ヘッダー画像を変更する</HeaderText>
        <HiddenInput data-param-name='header' type='file' ref={(ref) => this.headerInput = ref} accept='image/*' id='headerInput' onChange={handleChangeHeader} />
      </HeaderImgBox>
    );
  }
}

export default HeaderImage;
