// modules
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes, FaEdit, FaEye } from 'react-icons/fa';

// styles
import {
  ComponentSeparator,
  ComponentDeleteButton,
  UnderLine
} from '../../../styles/home';

class Separator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPreview: true
    };
  }

  render() {
    const { component, handleChangeDelete, handleBlurComponentNameInput } = this.props;

    return (
      <Container fluid>
        <Row>
          <ComponentSeparator>
            <input
              type='text'
              onBlur={handleBlurComponentNameInput}
              data-component-id={component.id}
              defaultValue={component.name}
              placeholder='表示したい文字を入力してください'/>
            <UnderLine id='separator-name-underline'/>
          </ComponentSeparator>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm'
          onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
      </Container>
    );
  }
}

export default Separator;
