import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import { connect, } from 'react-redux';
import compose from 'recompose/compose';

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      complete: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.account.id !== undefined) {
      return {
        complete: true,
      }
    }
    return null;
  }

  render() {
    const { history, account, redirectTo, component: Component, ...rest } = this.props;
    const { complete } = this.state;

    if(!complete) return <div />
    return (
      <Route
        {...rest}
        render={() => {
          if(account.id === null && redirectTo) return <Redirect to={redirectTo} />

          return <Component {...this.props} />
        }}
      />
    )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PrivateRoute);
