// modules
import React from 'react';
import { Container, Row } from 'reactstrap';

// components
import Header from '../../components/containers/Header';
import Footer from '../../components/presentionals/Footer';

// styles
import { StyledPolicy } from '../../styles/policy';

class UserPolicy extends React.Component {
  render() {
    return(
      <div>
        <div className='content'>
          <Header history={this.props.history}/>
          <Container>
            <Row>
              <StyledPolicy xs='12'>
                <h2>利用規約</h2>
                <h3>第1条（はじめに）</h3>
                <ol>
                  <li>
                    本利用規約は、株式会社grabss（以下「弊社」といいます）が運営する
                    アーティストプラットフォーム「TIGET Plus」において、
                    弊社が提供する各種サービス（以下「本サービス」といいます）において
                    基本となる利用条件を定めるものです。ユーザーは本利用規約および弊社が定める
                    本サービスの利用に関するヘルプやガイドラインの全てに同意の上、
                    本サービスを利用するものとします。弊社は、ユーザーが本サービスを利用した時点で、
                    本利用規約の内容に同意したものとみなします。
                  </li>
                </ol>
                <h3>第2条（定義）</h3>
                <p>本利用規約においては、次の用語はそれぞれ次の意味で使用します。</p>
                <ol>
                  <li>
                    「本サービス」弊社が運営するアーティストプラットフォーム TIGET Plus
                  </li>
                  <li>
                    「利用規約等」 本利用規約および個別利用規約
                  </li>
                  <li>
                    「本サービス」 利用規約等に基づき当社が会員に提供するサービス
                  </li>
                  <li>
                    「ユーザー」 利用規約等について弊社と合意し、本サービスの提供を受ける
                    個人または本サービスにおいて各種情報を発信する個人または法人
                    （個人が団体に所属する場合は、その法人または団体も含む）
                  </li>
                  <li>
                    「コンテンツ」 弊社又はユーザーが本サービスに掲載・発信した情報
                  </li>
                  <li>
                    「購入者」 ユーザーが本サービスにおいて掲載・発信したコンテンツを購入したユーザー
                  </li>
                  <li>
                    「利用料」 本サービスの利用に関連して、弊社がユーザーから徴収する料金
                    （なお、販売手数料、決済手数料その他の名称の如何を問わないものとします）
                  </li>
                  <li>
                    「販売価格」 ユーザーが本サービスにおいてコンテンツを他のユーザーに提供する際に設定する商品の価格をいいます。
                  </li>
                  <li>
                    「個人情報」 個人情報保護法に定める「個人情報」を指すものとし、
                    ユーザーが入力した氏名、郵便番号、住所、生年月日、性別、職業、電話番号、
                    アカウント情報（電子メールアドレス及びパスワード等をいう。）、
                    プロフィール情報（ニックネーム、趣味、家族構成、年齢その他の個人に関する属性情報）、
                    クレジットカード情報及び利用履歴等で、かつ特定の個人を識別できる情報
                    （他の情報と容易に照合することができ、それにより特定の個人を識別することとなるものを含む）
                  </li>
                  <li>
                    「パスワード」 Eメールと組み合わせて、ユーザーがログインする際に用いられる認証を得るため入力する文字及び数字の羅列
                  </li>
                  <li>
                    「反社会的勢力」 指定暴力団、指定暴力団関係団体の構成員又は関係者、
                    その他反社会的組織であり、公共の福祉に反する活動を行う団体
                  </li>
                </ol>
                <h3>第3条（通知）</h3>
                <ol>
                  <li>
                    弊社からユーザーへの通知は、利用規約等に特段の定めのない限り、
                    通知内容を電子メール、書面又は本サービスに掲載するなど、弊社が適当と判断する方法により行います。
                  </li>
                  <li>
                    前項の規定に基づき、弊社からユーザーへの通知を電子メールの送信又は
                    本サービスへの掲載の方法により行う場合には、ユーザーに対する当該通知は、
                    それぞれ電子メールの送信又は本サービスへの掲載がなされた時点から効力を生じるものとします。
                  </li>
                  <li>
                    ユーザーが弊社に通知、連絡又は問い合わせをする必要が生じた場合、
                    本サービスの指定のお問い合わせ先を利用するものとし、
                    電話や来訪を行うことはできないものとします。弊社は、かかる連絡又は問い合わせがあった場合、
                    弊社が定める方法により、ユーザーの本人確認を行うことができるものとします。
                    また、問合せに対する回答方法に関しては、弊社が適切と考える回答方法を利用することができるものとし、
                    その回答方法をユーザーが決めることはできないものとします。
                  </li>
                </ol>
                <h3>第4条（ユーザーの義務等）</h3>
                <ol>
                  <li>
                    ユーザーは、利用規約等に基づき、本サービスにおいて他のユーザーが発信した
                    コンテンツを閲覧または購入者として各種コンテンツを購入することができます。
                  </li>
                  <li>
                    ユーザーは、利用規約等に基づき、本サービスにおいて各種コンテンツを発信し、
                    購入者の募集をすることができます。
                  </li>
                  <li>
                    購入者としてコンテンツの購入をした場合、ユーザーとしてコンテンツを発信した場合、
                    ユーザーは利用規約等に同意したものとみなします。
                  </li>
                  <li>
                    ユーザーは、あらかじめ弊社の書面による承諾がない限り、本サービスのユーザー資格、
                    利用規約等に基づく権利または義務の全部または一部を第三者に貸与、譲渡、売買、質入等をできないものとします。
                  </li>
                </ol>
                <h3>第5条（ユーザー登録について）</h3>
                <ol>
                  <li>
                    ユーザーとなることを希望する者（以下、「ユーザー希望者」といいます）は、
                    ユーザー希望者本人が弊社所定の方法により入会の申込を行い、
                    弊社がこれに対し弊社所定の方法により承諾の通知を発信したときにユーザー登録が成立するものとします。
                    なお、ユーザー希望者は利用規約等の内容を承諾のうえ、かかる申込を行うものとし、
                    本サービスのユーザー希望者が申込を行った時点で、弊社は、
                    本サービスのユーザー希望者が利用規約等の内容を承諾しているものとみなします。
                  </li>
                  <li>
                    ユーザー登録は、必ず本人または所属する法人または団体が行ってください。
                    ユーザー登録の際には、虚偽の無い情報を登録するものとします。
                    また登録名やプロフィール等において第三者に不快を与える内容の文言は利用できません。
                  </li>
                  <li>
                    利用規約等に基づいて登録または提出されたユーザーの情報およびユーザーの
                    当サービスの利用により弊社が取得した情報については、
                    別途定める弊社の「プライバシーポリシー」に従って取り扱われるものとします。
                  </li>
                  <li>
                    弊社は前各項その他利用規約等の規定にかかわらず、
                    本サービスのユーザー希望者およびユーザーが次の各号のいずれかに該当する場合には、
                    ユーザー登録を拒否することができます。また、ユーザー登録を認めた後でも、
                    ユーザー登録を取り消すことができるものとします。
                    <ol>
                      <li>
                        本利用規約等に違反したことを理由としてユーザー資格を停止または除名されたことがあるとき
                      </li>
                      <li>
                        ユーザー登録の際に虚偽の記載、誤記があったとき、または記入もれがあったとき
                      </li>
                      <li>
                        利用規約等に基づく債務に履行を怠るおそれがあるとき
                      </li>
                      <li>
                        反社会的勢力の構成員またはその関係者、もしくはそのおそれがあると弊社が判断したとき
                      </li>
                      <li>
                        ユーザー希望者及びユーザーが18歳未満であるとき
                      </li>
                      <li>
                        その他弊社が不適当と判断したとき
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>第6条（パスワードの管理について）</h3>
                <ol>
                  <li>
                    弊社及び本サービスでは、ユーザーのパスワードの管理については関与いたしません。
                    ユーザーは、パスワードを第三者に開示、貸与、共有しないとともに、
                    第三者に漏えいすることのないよう厳重に管理するものとします。
                    弊社は、ユーザーのパスワードによる利用その他の行為は、全てユーザーによる利用とみなします。
                  </li>
                  <li>
                    ユーザーのパスワード等の管理不十分、使用上の過誤、
                    第三者の使用等による損害の責任はユーザーが負うものとし、弊社は一切責任を負いません。
                  </li>
                  <li>
                    ユーザーは、パスワードを第三者に知られた場合、
                    またはユーザーのパスワードが第三者に使用されている疑いのある場合には、
                    直ちに弊社にその旨を連絡するとともに、弊社の指示がある場合にはこれに従うものとします。
                    この場合、弊社はそのユーザーパスワード等を不正アカウントとして停止することができるものとします。
                  </li>
                  <li>
                    ユーザーは、定期的にパスワードを変更する義務があるものとし、
                    その義務を怠ったことにより損害が生じたとしても、 弊社は一切責任を負いません。
                  </li>
                </ol>
                <h3>第7条（メールマガジン等について）</h3>
                <ol>
                  <li>
                    ユーザーは、弊社が本サービスに関する案内、システムメンテナンスに関する告知、
                    その他弊社が適切と判断した情報を、電子メール等の手段によりユーザーに対して配信することを了承するものとします。
                  </li>
                </ol>
                <h3>第8条（届出事項の変更等について）</h3>
                <ol>
                  <li>
                    ユーザーは、入会申込の際に弊社に届け出た事項に変更のあった場合は、
                    遅滞なく弊社所定の様式により届け出るものとします。
                  </li>
                  <li>
                    弊社からのユーザーに対する通知は、ユーザーが弊社に申請した連絡先に発信することにより、
                    ユーザーに通常到達すべきときに到達したとみなされるものとします。
                  </li>
                </ol>
                <h3>第9条（退会について）</h3>
                <ol>
                  <li>
                    ユーザーは、弊社所定の手続きにより退会することができます。
                  </li>
                  <li>
                    ユーザーが死亡した場合、その他本人のユーザー資格の利用が不可能となる事由があったときは、
                    弊社は、当該ユーザーがその時点で退会したものとみなし、ユーザーパスワードを利用停止できるものとします。
                  </li>
                </ol>
                <h3>第10条 (ユーザー資格の停止、除名について）</h3>
                <ol>
                  <li>
                    弊社は、本サービスを適正に運営するために、ユーザーに以下各号の事由が認められると判断した場合、
                    予め通知することなく、データやコンテンツの削除、サービスの全部または一部の利用停止、
                    当該ユーザーアカウントを削除などの弊社が必要と判断する措置を講じることができるものとします。
                    ユーザーはこれに対して一切異議を申し立てず、またこれにより損害または不利益を被ったとしても、
                    弊社を免責し、賠償請求その他一切の請求を行わないものとします。
                    <ol>
                      <li>
                        利用規約等に違反した場合、もしくはそのおそれがあると弊社が判断した場合
                      </li>
                      <li>
                        登録事項に虚偽の内容または不足があった場合
                      </li>
                      <li>
                        反社会的勢力の関係者によって登録または使用された場合、もしくはそのおそれがあると弊社が判断した場合
                      </li>
                      <li>
                        登録された電子メール等の利用が停止されていた場合
                      </li>
                      <li>
                        ユーザーの信用不安が発生したと弊社が判断した場合
                      </li>
                      <li>
                        前各号の他、弊社が不適切と判断した場合
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>第11条（本サービスの変更、中止および譲渡について）</h3>
                <ol>
                  <li>
                    弊社は、本サービスの適切な運営を目的とする場合において、
                    ユーザーにあらかじめ通知することなく本サービスの内容や仕様を変更したり、
                    提供を停止したり中止したりすることができるものとします。
                    弊社は、変更または停止、中止等によりユーザーに損害または不利益が生じたとしても、
                    一切責任を負わないものとします。
                  </li>
                  <li>
                    弊社が本サービスに係る事業を第三者に譲渡する場合
                    （事業譲渡、会社分割その他本サービスが移転する一切の場合を含む。）には、
                    当該事業の譲渡に伴い、ユーザーの利用規約等に基づく契約上の地位、
                    本規約に基づく権利・義務及びユーザー登録に伴い登録された情報その他の情報を、
                    弊社は当該事業の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき予め承諾するものとします。
                  </li>
                </ol>
                <h3>第12条（禁止行為について）</h3>
                <ol>
                  <li>
                    ユーザーは、本サービスを利用するに際し、以下の行為を行ってはなりません。
                    <ol>
                      <li>
                        本サービスを不正の目的をもって利用する行為
                      </li>
                      <li>
                        知的財産権、肖像権、パブリシティ権その他の権利を侵害する行為
                      </li>
                      <li>
                        プライバシーを侵害する行為
                      </li>
                      <li>
                        名誉毀損行為、侮辱行為や他者の業務妨害となる行為
                      </li>
                      <li>
                        詐欺等の犯罪に結びつく行為
                      </li>
                      <li>
                        不正アクセス行為の防止等に関する法律に違反する行為、
                        電子計算機損壊等業務妨害罪（刑法第234条の2）に該当する行為をはじめ、
                        弊社及び他人のコンピューターに対して不正な操作を行う行為
                      </li>
                      <li>
                        コンピュータウイルスなど有害なプログラム等を送信もしくは提供する行為、または推奨する行為
                      </li>
                      <li>
                        その他の犯罪行為又は法令等に違反する一切の行為
                      </li>
                      <li>
                        弊社、ユーザーその他の第三者の情報を改ざん、消去する行為
                      </li>
                      <li>
                        弊社、ユーザーその他の第三者の設備を不正に利用し、またはその運営に支障を与える行為
                      </li>
                      <li>
                        法令、利用規約等または公序良俗に違反する行為
                      </li>
                      <li>
                        本サービスの運営を妨害する行為
                      </li>
                      <li>
                        その他弊社が不適当と判断する行為
                      </li>
                    </ol>
                  </li>
                  <li>
                    ユーザーが利用規約等に違反することにより、弊社が何らかの損害を被った場合、
                    弊社はユーザーに対して損害の賠償請求ができるものとします。
                  </li>
                </ol>
                <h3>第13条（弊社の責任の範囲について）</h3>
                <ol>
                  <li>
                    弊社は、本サービスの内容について、瑕疵やバグがないことは保証しておりません。
                  </li>
                  <li>
                    弊社は、ユーザーが本サービスを利用する際に、
                    コンピュータウイルスなど有害なプログラム等による損害を受けないことを
                    保証しておりません。また、それらに起因する損害についていかなる責任も負いません。
                  </li>
                  <li>
                    弊社は、利用者が本サービスを利用する際に発生する通信費用等について、一切負担いたしません。
                  </li>
                  <li>
                    弊社は、いかなる場合でも、ユーザーの逸失利益、間接損害、拡大損害、特別損害、
                    弁護士費用その他の本条に規定のない損害を賠償しないものとします。
                  </li>
                  <li>
                    弊社は、天災地変、騒乱、暴動、火災、停電等の不可抗力によりユーザーに
                    発生した損害については、債務不履行責任、不法行為責任、
                    その他法律上の請求原因の如何を問わず賠償の責任を負わないものとします。
                  </li>
                  <li>
                    弊社は、前項における不可抗力その他弊社の責に帰さない事由により、
                    本サービスの全部または一部が滅失または破損し、修復しないことを決定した場合には、
                    その旨をユーザーに通知して本サービスの全部または一部を廃止することができるものとします。
                  </li>
                  <li>
                    弊社はユーザーと購入者の間に生じた紛争等に関する責任は負いません。
                    コンテンツ購入等に関して万が一トラブルが生じた際には、
                    ユーザーと購入者との間で解決することとします。
                  </li>
                </ol>
                <h3>第14条（準拠法と裁判所管轄について）</h3>
                <ol>
                  <li>
                    利用規約等の成立、効力発生、解釈にあたっては日本法を準拠法とします。
                    また、利用規約等に関連して弊社とユーザーまたは第三者との間で生じた紛争については
                    弊社所在地を管轄する裁判所を第一審の専属的合意管轄裁判所とします。
                  </li>
                </ol>
                <h3>第15条（利用規約等の変更について）</h3>
                <ol>
                  <li>
                    弊社は、利用規約等を随時改定することがあります。利用規約等の改定後は、
                    改定後の利用規約等を適用するものとします。なお、ユーザーが利用規約等の
                    改定後に本サービスを利用した場合、改定後の利用規約等に同意したものとみなします。
                  </li>
                </ol>
                <h3>第16条（利用規約について）</h3>
                <ol>
                  <li>
                    利用規約等の一部の条項が無効とされた場合も、他の条項の効力には影響せず、
                    他の規定は有効とします。
                  </li>
                </ol>
                <div className='bottom-block'>
                  <p>平成30年9月10日制定</p>
                  <br/>
                  <p>アーティストプラットフォーム TIGET Plus</p>
                  <p>お問い合わせ：info@plus.tiget.net</p>
                  <p style={{fontWeight: 'bold'}}>お問い合わせ時のご注意</p>
                  <p style={{fontStyle: 'italic'}}>受付時間：土・日・祝日および、弊社休業日を除く9時から17時</p>
                  <p style={{fontStyle: 'italic'}}>お問い合わせの内容によっては、回答できない場合や、回答までに時間がかかる場合があります。</p>
                </div>
              </StyledPolicy>
            </Row>
          </Container>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default UserPolicy;
