// 追々各入力項目に当てていく
const ErrorMessages = {
  password: '半角8文字以上で入力してください。'
}

const Regex = {
  password: /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,}$/
}

export const password = value =>
  !value || !Regex.password.test(value) ? ErrorMessages.password : undefined
