import {actionTypes} from '../actions/error'

export const initialState = {
  errorMessage: null,
  statusCode: 0
};

export default function reducer(state = {}, action) {
  const { type } = action;
  switch (type) {
    case actionTypes.ERROR_RESPONSE:
      return Object.assign({}, state, {
        errorMessage: action.error.message,
        statusCode: action.error.response.status,
        colmn: action.error.response.data.colmn
      });
    case actionTypes.CLEAR_ERROR:
      return Object.assign({}, state, {errorMessage: null, statusCode: 0});
    default:
      return state;
  }
}
