// modules
import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  FacebookShareButton,
  TwitterShareButton,
  GooglePlusShareButton,
  TwitterIcon,
  FacebookIcon,
  GooglePlusIcon
} from 'react-share';

// styles
import {
  ModalAppButton,
  ModalCancelButton,
  ModalShare
} from '../../../styles/app';

class ConfirmModal extends React.Component {

  render() {
    const {
      handleConfirmed,
      isOpen,
      toggle,
      message,
      okButtonText,
      cancelButtonText,
      nestedModal,
      shareButtonUrl
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {(() => {
            if (shareButtonUrl) {
              return(
                <ModalShare>
                  <TwitterShareButton
                    title={'TIGET plus で活動を一瞬で届ける準備が整いました！' + '\n'}
                    url={shareButtonUrl}
                    via='tigetplus'>
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <FacebookShareButton
                    quote='TIGET plus で活動を一瞬で届ける準備が整いました！'
                    url={shareButtonUrl}
                    style={{marginLeft: '10px'}}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <GooglePlusShareButton
                    url={shareButtonUrl}
                    style={{marginLeft: '10px'}}>
                    <GooglePlusIcon size={32} round={true} />
                  </GooglePlusShareButton>
                </ModalShare>
              )
            }
          })()}
        </ModalHeader>
        <ModalBody>
        {message}
        {nestedModal}
        </ModalBody>
        <ModalFooter>
          <ModalAppButton onClick={handleConfirmed}>
            {(() => {
              return okButtonText || 'OK'
            })()}
          </ModalAppButton>
          <ModalCancelButton onClick={toggle}>
            {(() => {
              return cancelButtonText || 'キャンセル'
            })()}
          </ModalCancelButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmModal;
