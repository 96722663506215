// modules
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { arrayMove } from 'react-sortable-hoc';

// actions
import {
  updatePage,
  deletePageComponent,
  updatePageComponent,
  createPageComponent
} from '../../actions/pages';

// components
import { ComponentList } from '../presentionals/home/ComponentList';
import CreateModal from '../presentionals/home/CreateModal';
import DeleteModal from '../presentionals/home/ConfirmModal';

function mapStateToProps(state) {
  return {
    page: state.pages,
    types: state.types
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePage:(pageId, params, callback) => dispatch(updatePage(pageId, params, callback)),
    updatePageComponent:(pageId, componentId, params, callback) => dispatch(updatePageComponent(pageId, componentId, params, callback)),
    deletePageComponent:(pageId, componentId, params, callback) => dispatch(deletePageComponent(pageId, componentId, params, callback)),
    createPageComponent:(pageId, params, callback) => dispatch(createPageComponent(pageId, params, callback)),
  }
}

class PageComponents extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      deleteModal: false,
      createModal: false,
      createTypeId: null,
      deleteComponentId: null
    };
  }

  componentDidUpdate(prevProps, prevState){
    // 新規作成されたコンポーネントのIDを識別しスクロール&最初のInputにフォーカス
    const prevPageComponentIds = prevProps.page.components.map(function(component) {
      return component.id;
    });
    const currentPageComponentIds = this.props.page.components.map(function(component) {
      return component.id;
    });

    var newComponentId;
    prevPageComponentIds.concat(currentPageComponentIds).forEach(function(id) {
      if (!prevPageComponentIds.includes(id) && currentPageComponentIds.includes(id)) {
        newComponentId = id;
      }
    })
    var newComponentTypeName;
    this.props.page.components.forEach(function(component) {
      if (component.id && newComponentId) {
        newComponentTypeName = component.type_name;
      }
    })
    if (!newComponentId || newComponentTypeName === 'calendar') { return; }
    const newComponentElement = document.getElementById('component-id-' + newComponentId);
    newComponentElement.scrollIntoView(true);
    newComponentElement.getElementsByTagName('input').item(0).focus();
  }

  /**
   * コンポーネント作成用モーダルToggle
   * @type {void}
   */
  createModalToggle = () => {
    this.setState({
      createModal: !this.state.createModal,
      createTypeId: null
    });
  }

  /**
   * コンポーネント作成モーダルタイプ選択handle
   * @param {object} e
   * @type  {void}
   */
  handleSelectedComponentType = (e) => {
    const target = e.currentTarget;
    Array.prototype.forEach.call(target.parentNode.children, function(li) {
      li.classList.remove('active');
    })
    target.classList.add('active');
    this.setState({
      createTypeId : target.getAttribute('data-type-id')
    });
  }

  /**
   * コンポーネント作成確定ボタン押下handle
   * @type  {void}
   */
  handleCreateComponentConfirmed = () => {
    const createTypeId = this.state.createTypeId;
    if (!createTypeId) { return; }
    const type = this.props.types.find(function(element) {
      return element.id == createTypeId;
    });
    const sequences = this.props.page.components.map(function(component) {
      return component.sequence;
    });
    const newSequence = sequences.length ? Math.max.apply(null, sequences) + 1 : 1;

    this.props.createPageComponent(this.props.page.id, { sequence: newSequence, colmn: type.default_colmn, type_id:  type.id}, this.props.saveChangesCallBack);
    this.createModalToggle();
  }

  /**
   * コンポーネント削除用モーダルToggle
   * @type {void}
   */
  deleteModalToggle = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  }

  /**
   * コンポーネント削除ボタン押下handle
   * @param {object} e
   * @type  {void}
   */
  handleChangeDelete = (e) => {
    this.setState({
      deleteComponentId: e.currentTarget.getAttribute('data-component-id')
    });
    this.deleteModalToggle();
  }

  /**
   * コンポーネント削除確定ボタン押下handle
   * @type  {void}
   */
  handleDeleteConfirmed = () => {
    this.props.deletePageComponent(this.props.page.id, this.state.deleteComponentId, null, this.props.saveChangesCallBack);
    this.deleteModalToggle();
  }

  /**
   * コンポーネント並べ替えhandle
   * APIリクエスト前にsetStateすることでレンダリングのラグを解消しています
   * @param {number} oldIndex
   * @param {number} newIndex
   * @type  {void}
   */
  handleSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) return;
    const newPage = this.props.page;
    newPage.components = arrayMove(newPage.components, oldIndex, newIndex).map(function (component, i) {
      component.sequence = i + 1;
      return component;
    });
    this.setState({
      page: newPage
    });
    const param = {
      page: {
        components_attributes: newPage.components
      }
    }
    this.props.updatePage(this.props.page.id, param, this.props.saveChangesCallBack);
  }

  /**
   * コンポーネントアイテム入力エリアBlur(アウトフォーカス)handle
   * @param {object} e
   * @type  {void}
   */
  handleBlurDatasourceInput = (e) => {
    const componentId = e.currentTarget.getAttribute('data-component-id');
    const itemId = e.currentTarget.getAttribute('data-item-id');
    const newDataSource = e.currentTarget.value;
    const item = this.props.page.components.find(function (component) {
      return component.id == componentId;
    }).items.find(function (item) {
      return item.id == itemId;
    });
    const oldDataSource = item ? item.data_source : '';
    if (oldDataSource === newDataSource) return;
    const param = {
      component: {
        items_attributes: {
          id: itemId,
          data_source: newDataSource
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, componentId, param, this.props.saveChangesCallBack);
  }

  /**
   * コンポーネント名入力エリアBlur(アウトフォーカス)handle
   * @param {object} e
   * @type  {void}
   */
  handleBlurComponentNameInput = (e) => {
    const componentId = e.currentTarget.getAttribute('data-component-id');
    const newPageName = e.currentTarget.value;
    const component = this.props.page.components.find(function (component) {
      return component.id == componentId;
    });
    const oldPageName = component ? component.name : '';
    if (oldPageName === newPageName) return;
    const param = {
      component: {
        name: newPageName
      }
    }
    this.props.updatePageComponent(this.props.page.id, componentId, param, this.props.saveChangesCallBack);
  }

  /**
   * コンポーネントアイテム作成handle
   * @param {object} e
   * @type  {void}
   */
  handleCreateComponentItem = (e) => {
    const componentId = e.currentTarget.getAttribute('data-component-id');
    const param = {
      component: {
        items_attributes: {
          id: null,
          data_source: ''
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, componentId, param, () => {
      this.props.saveChangesCallBack();
      const componentElement = document.getElementById('component-id-' + componentId);
      const scrollableElement = componentElement.querySelectorAll('.scrollable')[0];
      if (scrollableElement) { scrollableElement.scrollTop = 0; }
    });
  }

  /**
   * SortableItemの子要素のClickイベントを優先させる
   * @param {object} e
   * @type  {void}
   */
  shouldCancelStart = (e) => {
    // sortableに拾われたくないDOM要素はidを[no-sortable*]とする
    const noSortable = !e.target.id.indexOf('no-sortable');
    return e.target.tagName.toLowerCase() !== 'div' && !noSortable;
  }

  render() {
    const { page, types } = this.props;
    const restrictedComponentsLength = page.components.filter(function(component) {
      return component.type_name !== 'calendar' && component.type_name !== 'separator';
    }).length;
    return (
      <div>
        <ComponentList
          useWindowAsScrollContainer={true}
          shouldCancelStart={this.shouldCancelStart}
          axis='xy'
          helperClass='clone'
          components={page.components}
          handleChangeDelete={this.handleChangeDelete}
          handleBlurDatasourceInput={this.handleBlurDatasourceInput}
          handleBlurComponentNameInput={this.handleBlurComponentNameInput}
          handleCreateComponentItem={this.handleCreateComponentItem}
          saveChangesCallBack={this.props.saveChangesCallBack}
          createModalToggle={this.createModalToggle}
          onSortEnd={this.handleSortEnd} />
        <DeleteModal
          isOpen={this.state.deleteModal}
          toggle={this.deleteModalToggle}
          handleConfirmed={this.handleDeleteConfirmed}
          message='選択したアイテムを本当に削除しますか？' />
        <CreateModal
          isOpen={this.state.createModal}
          toggle={this.createModalToggle}
          isRestricted={restrictedComponentsLength >= 6}
          handleSelectedComponentType={this.handleSelectedComponentType}
          handleCreateComponentConfirmed={this.handleCreateComponentConfirmed}
          types={types}/>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PageComponents);
