// modules
import styled from 'styled-components';
import {
  Nav,
  NavLink,
  Button,
  Container,
  DropdownMenu
} from 'reactstrap';

// アプリカラーリスト
export const appColors = {
  main  : '#3c8c8c',
  sub   : '#3c8c8c4c',
  accent: '#999'
}

export const StyledFooterContainer = styled(Container)`
  background: white;
  text-align: center;
  padding-top: 40px;

  & > div,ul {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    list-style: none;
  }

  & > ul,li {
    font-size: 13px;
    color: ${appColors.accent};
    & > a {
      color: ${appColors.accent};
    }
  }

  @media screen and (min-width:768px) {
    height: 200px;
    & > ul,li {
      display: inline;
      padding-right: 1em;
      padding-left: 1em;
    }

    & > ul,li+ li {
      border-left: 1px solid ${appColors.accent};
    }
  }

  @media screen and (max-width: 767px) {
    height: 300px;
    & > ul,li {
      margin-bottom: 10px;
    }
  }
`;

export const FooterLogoImage = styled.img`
  height: 30px;
  margin-bottom: 20px;
`;

export const FooterCopyright = styled.p`
  font-size: 13px;
  color: ${appColors.accent};
  margin-bottom: 0;
`;

export const HeaderNav = styled(Nav)`
  @media screen and (min-width:768px) {
    & > li {
      line-height: 1;
      padding-right: 0.5em;
      padding-left: 0.5em;
    }

    & > li+ li {
      border-left: 1px solid ${appColors.accent};
    }
  }
`;

export const HeaderBrandImage = styled.img`
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const HeaderLink = styled(NavLink)`
  cursor: pointer;
  font-size: 12px;
  @media screen and (min-width:768px) {
    padding: 0;
  }

  & > div > span {
    color: ${appColors.main};
  }
`;

export const AccountSettingsDropdownMenu = styled(DropdownMenu)`
  z-index: 1001;
  border-radius: 0;
  padding: 20px;
  font-size: 12px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  & > div {
    background: white;
    color: ${appColors.main};
    & > svg {
      margin-top: -2px;
    }
    & > span {
      margin-left: 10px;
    }
  }
`;

export const ModalCancelButton = styled(Button)`
  width: 120px;
  border-radius: 0;
  background: ${appColors.accent};
  border: thin solid ${appColors.accent};

  &:hover {
    background: white;
    color: ${appColors.accent};
    border: thin solid ${appColors.accent};
  }
`;

export const ModalAppButton = styled(Button)`
  width: 120px;
  border-radius: 0;
  background: ${appColors.main};
  border: thin solid ${appColors.main};

  &:hover {
    background: white;
    color: ${appColors.main};
    border: thin solid ${appColors.main};
  }
`;

export const OverlayLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  opacity: 0.6;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > .pixel-spinner, .pixel-spinner * {
    box-sizing: border-box;
  }

  & > .pixel-spinner {
    height: 70px;
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & > .pixel-spinner .pixel-spinner-inner {
    width: calc(70px / 7);
    height: calc(70px / 7);
    background-color: ${appColors.main};
    color: ${appColors.main};
    box-shadow: 15px 15px  0 0,
                -15px -15px  0 0,
                15px -15px  0 0,
                -15px 15px  0 0,
                0 15px  0 0,
                15px 0  0 0,
                -15px 0  0 0,
                0 -15px 0 0;
    animation: pixel-spinner-animation 2000ms linear infinite;
  }

  @keyframes pixel-spinner-animation {
    50% {
      box-shadow: 20px 20px 0px 0px,
                  -20px -20px 0px 0px,
                  20px -20px 0px 0px,
                  -20px 20px 0px 0px,
                  0px 10px 0px 0px,
                  10px 0px 0px 0px,
                  -10px 0px 0px 0px,
                  0px -10px 0px 0px;
    }
    75% {
      box-shadow: 20px 20px 0px 0px,
                  -20px -20px 0px 0px,
                  20px -20px 0px 0px,
                  -20px 20px 0px 0px,
                  0px 10px 0px 0px,
                  10px 0px 0px 0px,
                  -10px 0px 0px 0px,
                  0px -10px 0px 0px;
    }
    100% {
      transform: rotate(360deg);
    }
  }

  & > .loading span {
    position: relative;
    display: inline-block;

    color: black;
    font-size: 48px;
    @media screen and (min-width:768px) {
      font-size: 86px;
    }
  }
  & > .loading .reverse {
    color: ${appColors.main};
  }
  & > .loading span::after {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-text);
    color: ${appColors.main};
    transform: rotateY(-90deg);
    animation: loading 4s infinite;
  }
  & > .loading .reverse::after {
    color: black;
  }

  & > .loading span:nth-child(2)::after {
    animation-delay: .1s;
  }
  & > .loading span:nth-child(3)::after {
    animation-delay: .2s;
  }
  & > .loading span:nth-child(4)::after {
    animation-delay: .4s;
  }
  & > .loading span:nth-child(5)::after {
    animation-delay: .6s;
  }

  @keyframes loading {
    0%, 75%, 100% {
      transform: rotateY(-90deg);
      opacity: 0;
    }
    25%, 50% {
      transform: rotateY(0);
      opacity: 1;
    }
  }
`;

export const StyledNotFound = styled.div`
  font-size: 28px;
  color: black;
  text-align: center;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalShare = styled.div`
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
`;
