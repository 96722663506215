// modules
import React from 'react';
import compose from 'recompose/compose';
import { reduxForm, Field } from 'redux-form';
import { Row, Col, Label } from 'reactstrap';

// components
import TextField from '../../presentionals/top/TextField';

// styles
import {
  TopPanel,
  TopImage,
  LoginFormBox
} from '../../../styles/top';

import { topImagePath } from '../../../utils/assets.js.erb'

class LoginForm extends React.Component {
  render() {
    const {handleSubmit,onClickForgotPassword} = this.props;
    return(
      <Row>
        <TopPanel>
          <Row>
            <Col xs='12' sm='6'>
              <TopImage src={topImagePath}/>
            </Col>
            <LoginFormBox xs='12' sm='6'>
              <form onSubmit={handleSubmit}>
                <Label>TIGET plus ログイン</Label>
                <Field
                  name='email'
                  type='email'
                  component={TextField}
                  placeholder='メールアドレス'
                />
                <Field
                  name='password'
                  type='password'
                  component={TextField}
                  placeholder='パスワード'
                />
                <button type='submit' className='btn btn-primary'>ログイン</button>
                <br/>
                <a onClick={onClickForgotPassword}>パスワードをお忘れですか？</a>
              </form>
            </LoginFormBox>
          </Row>
        </TopPanel>
      </Row>
    );
  }
}

export default compose(
    reduxForm({form: 'loginForm'})
)(LoginForm);
