// modules
import React from 'react';
import { Row, Col } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// components
import Create from '../../../components/containers/pageComponents/Create';
import Calendar from '../../../components/containers/pageComponents/Calendar';
import Twitter from '../../../components/containers/pageComponents/Twitter';
import Facebook from '../../../components/containers/pageComponents/Facebook';
import Youtube from '../../../components/containers/pageComponents/Youtube';
import Information from '../../../components/containers/pageComponents/Information';
import Separator from '../../../components/containers/pageComponents/Separator';
import Photo from '../../../components/containers/pageComponents/Photo';
import Contact from '../../../components/containers/pageComponents/Contact';

// styles
import { ComponentBase } from '../../../styles/home';

const ComponentItem = SortableElement(({
  component,
  handleChangeDelete,
  handleBlurDatasourceInput,
  handleBlurComponentNameInput,
  handleCreateComponentItem,
  saveChangesCallBack}) => {
    switch (component.type_name) {
      case 'calendar':
      return (
        <ComponentBase xs='12' sm='12' id={'component-id-' + component.id} theme={{corsor: 'auto'}}>
          <Calendar
            key={component.id}
            component={component}
            handleChangeDelete={handleChangeDelete}
            handleCreateComponentItem={handleCreateComponentItem}
            saveChangesCallBack={saveChangesCallBack}/>
        </ComponentBase>
      )
      case 'twitter':
        return (
          <ComponentBase lg='4' md='6' xs='12' id={'component-id-' + component.id}>
            <Twitter
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}
              handleBlurDatasourceInput={handleBlurDatasourceInput}/>
          </ComponentBase>
        )
      case 'facebook':
        return (
          <ComponentBase lg='4' md='6' xs='12' id={'component-id-' + component.id}>
            <Facebook
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}
              handleBlurDatasourceInput={handleBlurDatasourceInput}/>
          </ComponentBase>
        )
      case 'youtube':
        return (
          <ComponentBase lg='4' md='6' xs='12' id={'component-id-' + component.id}>
            <Youtube
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}
              handleBlurDatasourceInput={handleBlurDatasourceInput}/>
          </ComponentBase>
        )
      case 'information':
        return (
          <ComponentBase lg='4' md='6' xs='12' id={'component-id-' + component.id}>
            <Information
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}
              handleBlurComponentNameInput={handleBlurComponentNameInput}
              handleCreateComponentItem={handleCreateComponentItem}
              saveChangesCallBack={saveChangesCallBack}/>
          </ComponentBase>
        )
      case 'separator':
        return (
          <ComponentBase xs='12' sm='12' id={'component-id-' + component.id}>
            <Separator
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}
              handleBlurComponentNameInput={handleBlurComponentNameInput}/>
          </ComponentBase>
        )
      case 'photo':
        return (
          <ComponentBase lg='4' md='6' xs='12' id={'component-id-' + component.id}>
            <Photo
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}
              saveChangesCallBack={saveChangesCallBack}/>
          </ComponentBase>
        )
      case 'contact':
        return (
          <ComponentBase lg='4' md='6' xs='12' id={'component-id-' + component.id}>
            <Contact
              key={component.id}
              component={component}
              handleChangeDelete={handleChangeDelete}/>
          </ComponentBase>
        )
      default:
        return <div/>
    }
});

export const ComponentList = SortableContainer(({
  components,
  handleBlurDatasourceInput,
  handleBlurComponentNameInput,
  handleCreateComponentItem,
  handleChangeDelete,
  createModalToggle,
  saveChangesCallBack}) => {
    return (
      <Row>
      {components.map((component, index) => (
        <ComponentItem key={`component-sortable-${component.id}`}
          index={index}
          disabled={component.type_name == 'calendar'}
          component={component}
          handleBlurDatasourceInput={handleBlurDatasourceInput}
          handleBlurComponentNameInput={handleBlurComponentNameInput}
          handleCreateComponentItem={handleCreateComponentItem}
          handleChangeDelete={handleChangeDelete}
          saveChangesCallBack={saveChangesCallBack}/>
      ))}
      <Create handleCreate={createModalToggle} />
      </Row>
    );
});
