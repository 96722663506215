// modules
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes, FaEdit, FaEye } from 'react-icons/fa';
import RYouTube from 'react-youtube';
import ReactTooltip from 'react-tooltip';

// styles
import {
  ComponentDeleteButton,
  ComponentEditButton,
  ComponentPreviwButton,
  ComponentContent,
  ComponentIconYoutube,
  ComponentDatasourceLabel,
  ComponentDatasourceInput
} from '../../../styles/home';

class Youtube extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPreview: false
    };
  }

  /**
   * キープレスhandle
   * @param {object} e
   * @type  {void}
   */
  handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      document.getElementById(this.props.component.id + '-data-source-input').blur();
      document.getElementById(this.props.component.id + '-preview').click();
    }
  }

  render() {
    const { component, handleChangeDelete, handleBlurDatasourceInput } = this.props;

    return (
      <Container fluid>
        <Row>
          <ComponentContent>
            <Row>
              <Col>
                <ComponentIconYoutube/>
              </Col>
            </Row>
            <Row>
              <Col>
                {(() => {
                  const item = component.items[0];
                  if (this.state.isPreview && item) {
                    return (
                      <RYouTube
                        className='mt-2'
                        id={component.id.toString() + '-preview'}
                        videoId={item.data_source}
                        opts={{
                          height: '400',
                          width: '100%'
                        }}/>
                    )
                  } else {
                    return (
                      <div>
                        <ComponentDatasourceLabel>Youtube動画ID<span data-tip data-for='youtube-help'>？</span></ComponentDatasourceLabel>
                        <ReactTooltip id='youtube-help' type='warning'>
                          <p style={{textAlign: 'center', padding: 0, margin: 0}}>
                            youtubeで動画を右クリックし「動画のURLをコピー」を選択してください。
                            <br/>
                            コピーされたURLの末尾11桁の半角英数字が動画IDです。
                            <br/>
                            https://youtu.be/S8Hsye0aYJAの場合はS8Hsye0aYJAの部分が動画IDです。
                            <br/>
                            https://www.youtube.com/watch?v=S8Hsye0aYJA&feature=youtu.beのような場合にもS8Hsye0aYJAの部分が動画IDです。
                          </p>
                        </ReactTooltip>
                        <ComponentDatasourceInput
                          id={component.id + '-data-source-input'}
                          data-component-id={component.id}
                          data-item-id={item.id}
                          type='text'
                          onBlur={handleBlurDatasourceInput}
                          onKeyPress={this.handleOnKeyPress}
                          defaultValue={item.data_source}
                          placeholder='Youtubeの動画IDを入力してください'/>
                      </div>
                    )
                  }
                })()}
              </Col>
            </Row>
          </ComponentContent>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm' onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
        <ComponentEditButton
          size='sm'
          onClick={ () => { this.setState({isPreview : false}) } }>
          <FaEdit />
        </ComponentEditButton>
        <ComponentPreviwButton
          id={component.id + '-preview'}
          size='sm'
          onClick={ () => { this.setState({isPreview : true}) } }>
          <FaEye />
        </ComponentPreviwButton>
      </Container>
    );
  }
}

export default Youtube;
