// modules
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Container, Row, Col, Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom'
import { notify } from 'react-notify-toast';

// actions
import {
  getPages,
  updatePage,
  previewPage,
  publishPage,
  rollbackPage
} from '../../actions/pages';
import { getTypes } from '../../actions/types';
import { clearError } from '../../actions/error';

// components
import Header from '../containers/Header';
import Footer from '../presentionals/Footer';
import HeaderImage from '../presentionals/home/HeaderImage';
import IconImage from '../presentionals/home/IconImage';
import PageInformation from '../presentionals/home/PageInformation';
import PageComponents from '../containers/PageComponents';
import PublishModal from '../presentionals/home/ConfirmModal';
import PublishCompletedModal from '../presentionals/home/ConfirmModal';
import RollbackModal from '../presentionals/home/ConfirmModal';

// styles
import { PageButton } from '../../styles/home';

// utils
import { syncUrl } from '../../utils/common';

function mapStateToProps(state) {
  return {
    account: state.account,
    page: state.pages,
    types: state.types,
    error: state.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearError:() => dispatch(clearError()),
    getPages:(params) => dispatch(getPages(params)),
    updatePage:(pageId, params, callback) => dispatch(updatePage(pageId, params, callback)),
    previewPage:(pageId, params, callback) => dispatch(previewPage(pageId, params, callback)),
    publishPage:(pageId, params, callback) => dispatch(publishPage(pageId, params, callback)),
    rollbackPage:(pageId, params, callback) => dispatch(rollbackPage(pageId, params, callback)),
    getTypes:(params) => dispatch(getTypes(params)),
  }
}

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      publishModal: false,
      publishCompletedModal: false,
      publishTimeout: false,
      rollbackModal: false
    };
  }

  componentDidMount(){
    this.props.getTypes();
    this.props.getPages();
  }

  componentDidUpdate(prevProps, prevState){
    const prevError = prevProps.error;
    const currentError = this.props.error;
    if (prevError.statusCode !== currentError.statusCode) {
      switch(currentError.statusCode){
        case 400:
          if (currentError.colmn === 'items.thumbnail') {
            notify.show('サムネイルに設定する画像は２メガバイト以下のファイルを選択してください。', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
          }
          break;
        case 500:
          notify.show('サーバーエラーが発生しました。しばらく時間を置いてお試しください。', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
        default:
          break;
      }
      currentError.statusCode = 0;
    }
  }

  /**
   * 変更通知用共通CallBack
   * @type {void}
   */
  saveChangesCallBack = () => {
    notify.show('変更を保存しました', 'custom', 800, { background: '#3c8c8cB3', text: '#fff' });
  }

  /**
   * 画像変更handle
   * @param {object} e
   * @type  {void}
   */
  handleChangeImage = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) return;
    const paramName = e.currentTarget.getAttribute('data-param-name');
    const data = new FormData();
    data.append('page['+paramName+']', file);
    this.props.updatePage(this.props.page.id, data, this.saveChangesCallBack);
  }

  /**
   * 画像削除handle
   * @param {object} e
   * @type  {void}
   */
  handleDeleteImage = (e) => {
    e.stopPropagation();
    const paramName = e.currentTarget.getAttribute('data-param-name');
    const param = {
      page: {
        ['remove_' + paramName]: true
      }
    }
    this.props.updatePage(this.props.page.id, param, this.saveChangesCallBack);
  }

  /**
   * 入力エリアBlur(アウトフォーカス)handle
   * @param {object} e
   * @type  {void}
   */
  handleBlurInput = (e) => {
    const paramName = e.currentTarget.getAttribute('data-param-name');
    const newValue = e.currentTarget.value;
    const oldValue = this.props.page[paramName] || '';
    // 改行コードを@に変換して比較
    if (escape(newValue.replace(/\r?\n/g, '@')) == escape(oldValue.replace(/\r?\n/g, '@'))) return;
    const param = {
      page: {
        [paramName]: newValue
      }
    }
    this.props.updatePage(this.props.page.id, param, this.saveChangesCallBack);
  }

  /**
   * プレビューボタン押下handle
   * @type  {void}
   */
  handleOnClickPreviewButton = (e) => {
    this.props.previewPage(this.props.page.id, null, (res) => {
      window.open(
        syncUrl()
        + 'users/'
        + this.props.account.tiget_user_id
        + '?is_preview=true&preview_token='
        + res['preview_token']
      );
    });
  }

  /**
   * 公開モーダルToggle
   * @type {void}
   */
  publishModalToggle = () => {
    this.setState({
      publishModal: !this.state.publishModal
    });
  }

  /**
   * 公開完了モーダルToggle
   * @type {void}
   */
  publishCompletedModalToggle = () => {
    this.setState({
      publishCompletedModal: false,
      publishModal: false
    });
  }

  /**
   * ロールバックモーダルToggle
   * @type {void}
   */
  rollbackModalToggle = () => {
    this.setState({
      rollbackModal: !this.state.rollbackModal
    });
  }

  /**
   * 公開モーダル確認handle
   * @type {void}
   */
  handlePublishConfirmed = () => {
    if (this.props.page.lock_reason) {
      this.setState({
        publishModal: false
      });
      notify.show('現在このページは公開が停止されています。', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
      return;
    }
    this.props.publishPage(this.props.page.id, null, (res) => {
      this.setState({
        publishCompletedModal: true,
        publishTimeout: res.status == 408
      });
    });
  }

  /**
   * ロールバックモーダル確認handle
   * @type {void}
   */
  handleRollbackConfirmed = () => {
    this.props.rollbackPage(this.props.page.id, null, (res) => {
      this.rollbackModalToggle();
    });
  }

  render() {
    const { page, error } = this.props;
    switch(error.statusCode){
      case 401:
        /* renderがpureじゃないので気に入らないが
           componentDidUpdateでのsetStateはご法度のためこちらで */
        this.props.account.id = null;
        return <Redirect to={'/'} />
      default:
        break;
    }
    if (page.id) {
      return(
        <div>
          <div className='content'>
            <Header history={this.props.history}/>
            {(() => {
              if (page.lock_reason) {
                return(
                  <Alert color='danger' style={{textAlign: 'center'}}>
                    <p>現在このページは以下の理由によって運営者によって公開が停止されています。</p>
                    <p style={{fontWeight: 'bold'}}>{page.lock_reason}</p>
                    <p style={{padding: 0, margin: 0}}>公開停止解除につきましては運営者にお問い合わせください。</p>
                    <p style={{padding: 0, margin: 0}}>問い合わせ先：info@plus.tiget.net</p>
                  </Alert>
                )
              }
            })()}
            <HeaderImage
              src={page.header}
              handleChangeHeader={this.handleChangeImage}
              handleDeleteHeader={this.handleDeleteImage}/>
            <IconImage
              src={page.icon}
              handleChangeIcon={this.handleChangeImage}
              handleDeleteIcon={this.handleDeleteImage}/>
            <Container fluid>
              <PageInformation page={page} handleBlurInput={this.handleBlurInput} />
              <Row className='mt-3'>
                <Col sm={{ size: 10, offset: 1 }} xs='12'>
                  <PageComponents saveChangesCallBack={this.saveChangesCallBack} />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col xs='12' className='text-center'>
                  <PageButton onClick={this.handleOnClickPreviewButton}>プレビュー</PageButton>
                  <PageButton onClick={this.publishModalToggle}>公開</PageButton>
                  <PageButton onClick={this.rollbackModalToggle}>前回公開状態に戻す</PageButton>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer/>
          <PublishModal
            isOpen={this.state.publishModal}
            toggle={this.publishModalToggle}
            handleConfirmed={this.handlePublishConfirmed}
            message='現在の設定をTIGET上に公開しますか？'
            nestedModal={(
              <PublishCompletedModal
                isOpen={this.state.publishCompletedModal}
                toggle={this.publishCompletedModalToggle}
                handleConfirmed={() => {
                  this.publishCompletedModalToggle();
                  window.open(
                    syncUrl()
                    + 'users/'
                    + this.props.account.tiget_user_id
                  );
                }}
                okButtonText='TIGETで確認'
                shareButtonUrl={
                  syncUrl()
                + 'users/'
                + this.props.account.tiget_user_id}
                message={
                  this.state.publishTimeout ?
                  '現在公開処理中です。完了までしばらく時間がかかる場合があります。' :
                  '公開が完了しました。'
                }/>
            )}/>
          <RollbackModal
            isOpen={this.state.rollbackModal}
            toggle={this.rollbackModalToggle}
            handleConfirmed={this.handleRollbackConfirmed}
            message='現在の設定を破棄して前回公開状態に戻しますか？'/>
        </div>
      );
    } else {
      return <div/> // TODO : Loading
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
