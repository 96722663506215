// modules
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Container, Row, Col } from 'reactstrap';
import { FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import FullCalendar from 'fullcalendar-reactwrapper';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// actions
import { updatePageComponent } from '../../../actions/pages';

// components
import ThumbnailImage from '../../presentionals/home/ThumbnailImage';
import SettingLinkModal from '../../presentionals/home/SettingLinkModal';

// styles
import {
  UnderLine,
  ComponentEditButton,
  ComponentPreviwButton,
  ComponentHeader,
  ComponentContent,
  ComponentItemCreateButton,
  ComponentItemList,
  ComponentItem,
  ComponentItemStartDateInput,
  ComponentItemStartDatePreview,
  ComponentItemTextBlock,
  ComponentItemDatePickerInput,
  ComponentItemNameSelectArrow,
  ComponentItemNameSelect,
  ComponentItemNamePreview,
  ComponentItemDataSouceInput,
  ComponentItemDataSoucePreview,
  ComponentItemButton
} from '../../../styles/home';

function mapStateToProps(state) {
  return {
    page: state.pages,
    supports: state.supports
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePageComponent:(pageId, componentId, params, callback) => dispatch(updatePageComponent(pageId, componentId, params, callback))
  }
}

class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPreview: false,
      settingLinkItem: null,
      settingLinkModal: false,
      datePickerTargetItemId: null
    };
  }

  /**
   * FullCalendarDay選択handle
   * @param {object} date
   * @param {object} jsEvent
   * @type  {void}
   */
  handleClickDate = (date, jsEvent) => {
    // TODO : Clickアクション
  }

  /**
   * サムネイル変更handle
   * @param {object} e
   * @type  {void}
   */
  handleChangeThumbnail = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) return;
    const itemId = e.currentTarget.getAttribute('data-item-id');
    const data = new FormData();
    data.append('component[items_attributes][id]', itemId);
    data.append('component[items_attributes][thumbnail]', file);
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, data, this.props.saveChangesCallBack);
  }

  /**
   * サムネイル削除handle
   * @param {object} e
   * @type  {void}
   */
  handleDeleteThumbnail = (e) => {
    e.stopPropagation();
    const itemId = e.currentTarget.getAttribute('data-item-id');
    const param = {
      component: {
        items_attributes: {
          id: itemId,
          remove_thumbnail: true
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  /**
   * 入力エリアBlur or Change handle
   * @param {object} e
   * @type  {void}
   */
  handleChangeInput = (e) => {
    const paramName = e.currentTarget.getAttribute('data-param-name');
    const newValue = e.currentTarget.value;
    const currentItem = this.props.component.items.find(function(element) {
      return element.id == e.currentTarget.getAttribute('data-item-id');
    });
    const oldValue = currentItem[paramName] || '';
    // 改行コードを@に変換して比較
    if (escape(newValue.replace(/\r?\n/g, '@')) == escape(oldValue.replace(/\r?\n/g, '@'))) return;
    const param = {
      component: {
        items_attributes: {
          id: currentItem.id,
          [paramName]: newValue
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  /**
   * コンポーネントアイテム削除ボタンhandle
   * @param {object} e
   * @type  {void}
   */
  handleDeleteComponentItem = (e) => {
    const param = {
      component: {
        items_attributes: {
          id: e.currentTarget.getAttribute('data-item-id'),
          _destroy: true
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  handleFocusDatePicker = (e) => {
    const itemId = e.currentTarget.getAttribute('data-item-id');
    this.setState({datePickerTargetItemId : itemId});
  }

  handleChangeDatePicker = (date, e) => {
    const paramName = 'start_date';
    const newValue = moment(date).format('YYYY-MM-DD');
    const itemId = this.state.datePickerTargetItemId;
    this.setState({datePickerTargetItemId : null});

    const currentItem = this.props.component.items.find(function(element) {
      return element.id == itemId;
    });
    const oldValue = currentItem[paramName] || '';
    // 改行コードを@に変換して比較
    if (escape(newValue.replace(/\r?\n/g, '@')) == escape(oldValue.replace(/\r?\n/g, '@'))) return;
    const param = {
      component: {
        items_attributes: {
          id: currentItem.id,
          [paramName]: newValue
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, () => {
      this.props.saveChangesCallBack();
      const componentElement = document.getElementById('component-id-' + this.props.component.id);
      const scrollableElement = componentElement.querySelectorAll('.scrollable')[0];
      const scrollableItemElements = scrollableElement.querySelectorAll('.scrollable-item');
      var changedItemElement;
      Array.from(scrollableItemElements, e => {
        if (currentItem.id == e.getAttribute('data-item-id')) {
          changedItemElement = e;
        }
      });
      if (scrollableElement && changedItemElement) {
        scrollableElement.scrollTop = changedItemElement.offsetParent.offsetTop - 60;
      }
    });
  }

  /**
   * リンク設定用モーダルToggle
   * @param {object} e
   * @type {void}
   */
  settingLinkModalToggle = (e) => {
    const currentItem = this.props.component.items.find(function(element) {
      const currentItemId = e ? e.currentTarget.getAttribute('data-item-id') : null;
      return element.id == currentItemId;
    });
    this.setState({
      settingLinkModal: !this.state.settingLinkModal,
      settingLinkItem: currentItem
    });
  }

  /**
   * リンク設定確定handle
   * @param {object} e
   * @type  {void}
   */
  handleSettingConfirmed = (e) => {
    const currentItem = this.state.settingLinkItem;
    const newValue = document.getElementById('item-link').value || null;
    // TODO : URL判定
    if (currentItem.link != newValue) {
      const param = {
        component: {
          items_attributes: {
            id: currentItem.id,
            link: newValue
          }
        }
      }
      this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
    }
    this.settingLinkModalToggle();
  }

  render() {
    const {
      component,
      handleCreateComponentItem,
      supports
    } = this.props;

    const eventProviders = supports.filter(function(item){
      return item.category === 'event_provider';
    });

    const {
      handleChangeInput,
      handleChangeThumbnail,
      handleDeleteThumbnail,
      handleDeleteComponentItem,
      handleChangeDatePicker,
      handleFocusDatePicker,
      settingLinkModalToggle
    } = this;

    const {
      isPreview
    } = this.state;

    // fullcalendar用
    const events = [];
    // コンポーネント用
    const items = [];

    component.items.forEach(function(item) {
      if (item.start_date) {
        events.push({
          start: item.start_date,
          itemId: item.id
        });
      }

      if (isPreview) {
        if (item.start_date) {
          var startDateSplitArray = item.start_date.split('-');
          var startDate = new Date(startDateSplitArray[0], startDateSplitArray[1] - 1, startDateSplitArray[2]);
          var startDateFormat = 'YYYY年MM月DD日';
          startDateFormat = startDateFormat.replace(/YYYY/, startDate.getFullYear());
          startDateFormat = startDateFormat.replace(/MM/, startDate.getMonth() + 1);
          startDateFormat = startDateFormat.replace(/DD/, startDate.getDate());
          items.push(
            <ComponentItem key={item.id}>
              <ComponentItemStartDatePreview>
                {startDateFormat}
              </ComponentItemStartDatePreview>
              {(() => {
                if (item.is_configured_thumbnail) {
                  return <ThumbnailImage item={item} isPreview={true} />
                }
              })()}
              <ComponentItemTextBlock>
                <ComponentItemDataSoucePreview href={item.link}>{item.data_source || item.link}</ComponentItemDataSoucePreview>
                <ComponentItemNamePreview>{item.name}</ComponentItemNamePreview>
              </ComponentItemTextBlock>
            </ComponentItem>
          )
        }
      } else {
        var nameSelectOptions = eventProviders.map(function(eventProvider) {
          return <option value={eventProvider.name} key={eventProvider.id}>{eventProvider.name}</option>;
        });
        nameSelectOptions.unshift(<option value={''} key='blank'></option>)
        items.push(
          <ComponentItem key={item.id}>
            <DatePicker
              className='scrollable-item'
              dateFormat='YYYY/MM/DD'
              placeholderText='日付を選択してください'
              customInput={
                <ComponentItemDatePickerInput data-item-id={item.id}/>
              }
              selected={ item.start_date ? moment(item.start_date) : null }
              onChange={handleChangeDatePicker}
              onFocus={handleFocusDatePicker}
              locale='ja'
              dateFormatCalendar='YYYY/MM'
              withPortal>
            </DatePicker>
            <UnderLine id='item-date-picker-underline'/>
            <ThumbnailImage
              item={item}
              handleChangeThumbnail={handleChangeThumbnail}
              handleDeleteThumbnail={handleDeleteThumbnail}/>
            <ComponentItemTextBlock>
              <ComponentItemDataSouceInput
                data-item-id={item.id}
                data-param-name='data_source'
                defaultValue={item.data_source}
                onBlur={handleChangeInput}
                placeholder='概要'/>
              <UnderLine id='item-data-source-underline'/>
              <ComponentItemNameSelectArrow>
                <ComponentItemNameSelect
                  value={item.name || ''}
                  onChange={handleChangeInput}
                  data-item-id={item.id}
                  data-param-name='name'>
                  {nameSelectOptions}
                </ComponentItemNameSelect>
                <UnderLine id='item-select-underline'/>
              </ComponentItemNameSelectArrow>
            </ComponentItemTextBlock>
            <div className='d-flex justify-content-end'>
              <ComponentItemButton
                data-item-id={item.id}
                size='sm'
                onClick={settingLinkModalToggle}>
                {(() => {
                  return item.link ? 'リンク設定済' : 'リンクを設定';
                })()}
              </ComponentItemButton>
              <ComponentItemButton
                data-item-id={item.id}
                size='sm'
                onClick={handleDeleteComponentItem}>
                アイテムを削除
              </ComponentItemButton>
            </div>
          </ComponentItem>
        )
      }
    });

    return (
      <Container fluid>
        <Row>
          <ComponentHeader>イベントカレンダー</ComponentHeader>
        </Row>
        <Row>
          <ComponentContent theme={{spHeight: '900px'}}>
            <Row>
              <Col xs='12' sm='4' className='mt-2'>
                <FullCalendar
                  id={component.id}
                  height={430}
                  aspectRatio={10}
                  locale={'ja'}
                  header={{
                    left: 'today',
                    center: 'prev, title, next',
                    right: ''
                  }}
                  titleFormat={'YYYY/MM'}
                  buttonText={{
                    today: '今日'
                  }}
                  events={events}
                  dayClick={this.handleClickDate}/>
              </Col>
              <Col xs='12' sm='8'>
                {(() => {
                  if (!this.state.isPreview) {
                    return(
                      <ComponentItemCreateButton
                        onClick={handleCreateComponentItem}
                        data-component-id={component.id}>
                        <FaPlus />
                      </ComponentItemCreateButton>
                    )
                  }
                })()}
                <ComponentItemList
                  theme={{height: '380px', marginTop: this.state.isPreview ? '54px' : ''}}
                  className='scrollable'>
                  {items}
                </ComponentItemList>
              </Col>
            </Row>
          </ComponentContent>
        </Row>
        <ComponentEditButton
          size='sm'
          onClick={ () => { this.setState({isPreview : false}) } }>
          <FaEdit />
        </ComponentEditButton>
        <ComponentPreviwButton
          size='sm'
          onClick={ () => { this.setState({isPreview : true}) } }>
          <FaEye />
        </ComponentPreviwButton>
        <SettingLinkModal
          isOpen={this.state.settingLinkModal}
          toggle={this.settingLinkModalToggle}
          handleSettingConfirmed={this.handleSettingConfirmed}
          settingLinkItem={this.state.settingLinkItem}/>
      </Container>
    );
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Calendar);
