// modules
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes, FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import { arrayMove } from 'react-sortable-hoc';

// actions
import { updatePageComponent } from '../../../actions/pages';

// components
import ThumbnailImage from '../../presentionals/home/ThumbnailImage';
import SettingLinkModal from '../../presentionals/home/SettingLinkModal';
import { InformationItemList } from '../../presentionals/home/InformationItemList';

// styles
import {
  UnderLine,
  ComponentDeleteButton,
  ComponentEditButton,
  ComponentPreviwButton,
  ComponentContent,
  ComponentNameInput,
  ComponentItemList,
  ComponentItem,
  ComponentItemCreateButton,
  ComponentItemTextBlock,
  ComponentItemNamePreview,
  ComponentItemDataSoucePreview
} from '../../../styles/home';

function mapStateToProps(state) {
  return {
    page: state.pages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePageComponent:(pageId, componentId, params, callback) => dispatch(updatePageComponent(pageId, componentId, params, callback))
  }
}

class Information extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPreview: false,
      settingLinkItem: null,
      settingLinkModal: false
    };
  }

  /**
   * リンク設定用モーダルToggle
   * @param {object} e
   * @type {void}
   */
  settingLinkModalToggle = (e) => {
    const currentItem = this.props.component.items.find(function(element) {
      const currentItemId = e ? e.currentTarget.getAttribute('data-item-id') : null;
      return element.id == currentItemId;
    });
    this.setState({
      settingLinkModal: !this.state.settingLinkModal,
      settingLinkItem: currentItem
    });
  }

  /**
   * サムネイル変更handle
   * @param {object} e
   * @type  {void}
   */
  handleChangeThumbnail = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) return;
    const itemId = e.currentTarget.getAttribute('data-item-id');
    const data = new FormData();
    data.append('component[items_attributes][id]', itemId);
    data.append('component[items_attributes][thumbnail]', file);
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, data, this.props.saveChangesCallBack);
  }

  /**
   * サムネイル削除handle
   * @param {object} e
   * @type  {void}
   */
  handleDeleteThumbnail = (e) => {
    e.stopPropagation();
    const itemId = e.currentTarget.getAttribute('data-item-id');
    const param = {
      component: {
        items_attributes: {
          id: itemId,
          remove_thumbnail: true
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  /**
   * 入力エリアBlur(アウトフォーカス)handle
   * @param {object} e
   * @type  {void}
   */
  handleBlurInput = (e) => {
    const paramName = e.currentTarget.getAttribute('data-param-name');
    const newValue = e.currentTarget.value;
    const currentItem = this.props.component.items.find(function(element) {
      return element.id == e.currentTarget.getAttribute('data-item-id');
    });
    const oldValue = currentItem[paramName] || '';
    // 改行コードを@に変換して比較
    if (escape(newValue.replace(/\r?\n/g, '@')) == escape(oldValue.replace(/\r?\n/g, '@'))) return;
    const param = {
      component: {
        items_attributes: {
          id: currentItem.id,
          [paramName]: newValue
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  /**
   * リンク設定確定handle
   * @param {object} e
   * @type  {void}
   */
  handleSettingConfirmed = (e) => {
    const currentItem = this.state.settingLinkItem;
    const newValue = document.getElementById('item-link').value || null;
    // TODO : URL判定
    if (currentItem.link != newValue) {
      const param = {
        component: {
          items_attributes: {
            id: currentItem.id,
            link: newValue
          }
        }
      }
      this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
    }
    this.settingLinkModalToggle();
  }

  /**
   * コンポーネントアイテム削除ボタンhandle
   * @param {object} e
   * @type  {void}
   */
  handleDeleteComponentItem = (e) => {
    const param = {
      component: {
        items_attributes: {
          id: e.currentTarget.getAttribute('data-item-id'),
          _destroy: true
        }
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  /**
   * コンポーネントアイテム並べ替えhandle
   * @param {number} oldIndex
   * @param {number} newIndex
   * @type  {void}
   */
  handleSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) return;
    const newComponent = this.props.component;
    newComponent.items = arrayMove(newComponent.items, oldIndex, newIndex).map(function (item, i) {
      item.sequence = i + 1;
      return item;
    });
    this.setState({
      component: newComponent
    });
    const param = {
      component: {
        items_attributes: newComponent.items.map((item) => ({
          id: item.id,
          sequence: item.sequence
        }))
      }
    }
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
  }

  /**
   * SortableItemの子要素のClickイベントを優先させる
   * @param {object} e
   * @type  {void}
   */
  shouldCancelStart = (e) => {
    return e.target.tagName.toLowerCase() !== 'div';
  }

  render() {
    const {
      component,
      handleChangeDelete,
      handleBlurComponentNameInput,
      handleCreateComponentItem
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <ComponentContent>
            <Row>
              <Col>
                <ComponentNameInput
                  type='text'
                  data-component-id={component.id}
                  defaultValue={component.name}
                  onBlur={handleBlurComponentNameInput}
                  placeholder='タイトル未設定'/>
                <UnderLine id='component-name-underline'/>
              </Col>
            </Row>
            <Row>
              {(() => {
                const items = [];
                if (this.state.isPreview) {
                  component.items.map((item) => (
                    items.push(
                      <ComponentItem key={item.id}>
                        <hr/>
                        {(() => {
                          if (item.is_configured_thumbnail) {
                            return <ThumbnailImage item={item} isPreview={true} />
                          }
                        })()}
                        <ComponentItemTextBlock>
                          <ComponentItemNamePreview>{item.name}</ComponentItemNamePreview>
                          <ComponentItemDataSoucePreview href={item.link}>{item.data_source || item.link}</ComponentItemDataSoucePreview>
                        </ComponentItemTextBlock>
                      </ComponentItem>
                    )
                  ));
                  return (
                    <Col>
                      <ComponentItemList theme={{height: '380px'}}>
                        {items}
                      </ComponentItemList>
                    </Col>
                  )
                } else {
                  return (
                    <Col>
                      <ComponentItemCreateButton
                        onClick={handleCreateComponentItem}
                        data-component-id={component.id}>
                        <FaPlus />
                      </ComponentItemCreateButton>
                      <InformationItemList
                        useWindowAsScrollContainer={true}
                        shouldCancelStart={this.shouldCancelStart}
                        axis='y'
                        lockAxis='y'
                        helperClass='clone'
                        items={component.items}
                        handleChangeThumbnail={this.handleChangeThumbnail}
                        handleDeleteThumbnail={this.handleDeleteThumbnail}
                        handleBlurInput={this.handleBlurInput}
                        settingLinkModalToggle={this.settingLinkModalToggle}
                        handleDeleteComponentItem={this.handleDeleteComponentItem}
                        onSortEnd={this.handleSortEnd}/>
                    </Col>
                  )
                }
              })()}
            </Row>
          </ComponentContent>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm'
          onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
        <ComponentEditButton
          size='sm'
          onClick={ () => { this.setState({isPreview : false}) } }>
          <FaEdit />
        </ComponentEditButton>
        <ComponentPreviwButton
          size='sm'
          onClick={ () => { this.setState({isPreview : true}) } }>
          <FaEye />
        </ComponentPreviwButton>
        <SettingLinkModal
          isOpen={this.state.settingLinkModal}
          toggle={this.settingLinkModalToggle}
          handleSettingConfirmed={this.handleSettingConfirmed}
          settingLinkItem={this.state.settingLinkItem}/>
      </Container>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Information);
