// modules
import React from 'react';
import { FaTimes } from 'react-icons/fa';

// styles
import {
  IconImgBox,
  IconImg,
  HiddenInput,
  IconText,
  ComponentDeleteButton
} from '../../../styles/home';

import { iconNoImagePath } from '../../../utils/assets.js.erb'

class IconImage extends React.Component {

  render() {
    const { src, handleChangeIcon, handleDeleteIcon } = this.props;
    return (
      <IconImgBox className='mx-auto' onClick={(event) => iconInput.click() }>
        {(() => {
          if (src) {
            return(
              <ComponentDeleteButton
                size='sm'
                data-param-name='icon'
                onClick={handleDeleteIcon}>
                <FaTimes/>
              </ComponentDeleteButton>
            )
          }
        })()}
        <IconImg src={src || iconNoImagePath} />
        <IconText>アイコン画像を変更する</IconText>
        <HiddenInput data-param-name='icon' type='file' ref={(ref) => this.IconInput = ref} accept='image/*' id='iconInput' onChange={handleChangeIcon} />
      </IconImgBox>
    );
  }
}

export default IconImage;
