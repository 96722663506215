// modules
import React from 'react';

// components
import Header from '../containers/Header';
import Footer from '../presentionals/Footer';

// styles
import { StyledNotFound } from '../../styles/app';

class NotFound extends React.Component {
  render() {
    return(
      <div>
        <div className='content'>
          <Header history={this.props.history}/>
          <StyledNotFound>
            ページが存在しません
          </StyledNotFound>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default NotFound;
