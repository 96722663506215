// modules
import React from 'react';
import { FaPlus } from 'react-icons/fa';

// styles
import { ComponentBase, ComponentCreate } from '../../../styles/home';

class Create extends React.Component {

  render() {
    const { handleCreate } = this.props;

    return (
      <ComponentBase lg='4' md='6' xs='12'>
        <ComponentCreate onClick={handleCreate}><FaPlus /></ComponentCreate>
      </ComponentBase>
    );
  }
}

export default Create;
