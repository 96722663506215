// actions
import {actionTypes} from '../actions/pages'

export const initialState = {
  id: null,
  components: []
};

/**
 * !!! 注意 !!!
 * DBの設計は複数pageを想定した作りとなっていますが暫定的に単数resouceとして扱います。
 * ファイル名はpagesですが実際はpageとして利用してください。
 */
export default function reducer(state = {}, action) {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_PAGES_RESPONSE:
      return Object.assign({}, state, action.payload[0]);
    case actionTypes.UPDATE_PAGE_RESPONSE:
    case actionTypes.ROLLBACK_PAGE_RESPONSE:
      return Object.assign({}, state, action.payload);
    case actionTypes.CREATE_PAGE_COMPONENT_RESPONSE:
      const createdComponents = state.components.concat(action.payload);
      return Object.assign({}, state, {components: createdComponents});
    case actionTypes.UPDATE_PAGE_COMPONENT_RESPONSE:
      const updatedComponents = state.components.map((component) => {
        return component.id == action.payload.id ? action.payload : component;
      });
      return Object.assign({}, state, {components: updatedComponents});
    case actionTypes.DELETE_PAGE_COMPONENT_RESPONSE:
      // DELETEのレスポンスにシーケンス整備後のcomponents配列を返すのでそれをセット
      return Object.assign({}, state, {components: action.payload});
    default:
      return state;
  }
}
