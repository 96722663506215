// modules
import React from 'react';
import {
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc';

// components
import ThumbnailImage from '../../presentionals/home/ThumbnailImage';

// styles
import {
  UnderLine,
  ComponentItemList,
  ComponentItem,
  ComponentItemTextBlock,
  ComponentItemNameInput,
  ComponentItemDataSouceInput,
  ComponentItemButton
} from '../../../styles/home';

const InformationItem = SortableElement(({
  item,
  handleChangeThumbnail,
  handleDeleteThumbnail,
  handleBlurInput,
  settingLinkModalToggle,
  handleDeleteComponentItem}) => {
    return(
      <ComponentItem key={item.id}>
        <hr/>
        <ThumbnailImage
          item={item}
          handleChangeThumbnail={handleChangeThumbnail}
          handleDeleteThumbnail={handleDeleteThumbnail}/>
        <ComponentItemTextBlock>
          <ComponentItemNameInput
            data-item-id={item.id}
            data-param-name='name'
            type='text'
            defaultValue={item.name}
            onBlur={handleBlurInput}
            placeholder='タイトル'/>
          <UnderLine id='item-name-underline'/>
          <ComponentItemDataSouceInput
            data-item-id={item.id}
            data-param-name='data_source'
            defaultValue={item.data_source}
            onBlur={handleBlurInput}
            placeholder='概要'/>
          <UnderLine id='item-data-source-underline'/>
        </ComponentItemTextBlock>
        <div className='d-flex justify-content-end'>
          <ComponentItemButton
            data-item-id={item.id}
            size='sm'
            onClick={settingLinkModalToggle}>
            {(() => {
              return item.link ? 'リンク設定済' : 'リンクを設定';
            })()}
          </ComponentItemButton>
          <ComponentItemButton
            data-item-id={item.id}
            size='sm'
            onClick={handleDeleteComponentItem}>
            アイテムを削除
          </ComponentItemButton>
        </div>
      </ComponentItem>
    )
  });

export const InformationItemList = SortableContainer(({
  items,
  handleChangeThumbnail,
  handleDeleteThumbnail,
  handleBlurInput,
  settingLinkModalToggle,
  handleDeleteComponentItem}) => {
    return (
      <ComponentItemList theme={{height: '320px'}} className='scrollable'>
        {items.map((item, index) => (
          <InformationItem
            key={`information-item-sortable-${item.id}`}
            index={index}
            item={item}
            handleChangeThumbnail={handleChangeThumbnail}
            handleDeleteThumbnail={handleDeleteThumbnail}
            handleBlurInput={handleBlurInput}
            settingLinkModalToggle={settingLinkModalToggle}
            handleDeleteComponentItem={handleDeleteComponentItem}/>
        ))}
      </ComponentItemList>
    );
});
