// modules
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

// styles
import {
  ComponentDeleteButton,
  ComponentContent,
  ComponentNamePreview,
  ContactInput,
  ContactTextArea,
  ContactButton,
  ContactCaption
} from '../../../styles/home';

class Contact extends React.Component {

  render() {
    const { component, handleChangeDelete } = this.props;

    return (
      <Container fluid>
        <Row>
          <ComponentContent>
            <Row>
              <ComponentNamePreview xs={12}>お問い合わせ<span data-tip data-for='contact-help'>？</span></ComponentNamePreview>
              <ReactTooltip id='contact-help' type='warning'>
                <p style={{textAlign: 'center', padding: 0, margin: 0}}>
                  お問い合わせ内容はアカウント登録されたメールアドレスに届きます。
                </p>
              </ReactTooltip>
              <Col xs={12}>
                <ContactInput
                  type='text'
                  readOnly
                  value='お名前'/>
                <ContactInput
                  type='text'
                  readOnly
                  value='メールアドレス'/>
                <ContactTextArea
                  type='text'
                  readOnly
                  value='お問い合わせ内容'/>
                <ContactButton>送信確認</ContactButton>
                <ContactCaption>
                  フォームに入力した情報は全て主催者に通知されます。
                  <br/>
                  お問い合わせはTIGETから送信されます。
                </ContactCaption>
              </Col>
            </Row>
          </ComponentContent>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm'
          onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
      </Container>
    );
  }
}

export default Contact;
