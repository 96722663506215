// modules
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Dropdown,
  DropdownToggle
} from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import { FaCog, FaSignOutAlt, FaHome } from 'react-icons/fa';

// actions
import { logout } from '../../actions/account';

// components
import Loading from '../presentionals/Loading';

// styles
import {
  HeaderNav,
  HeaderBrandImage,
  HeaderLink,
  AccountSettingsDropdownMenu
} from '../../styles/app';

import { tigetPlusIconPath } from '../../utils/assets.js.erb'

function mapStateToProps(state) {
  return {
    account: state.account,
    loading: state.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout:(callback) => dispatch(logout(callback)),
  }
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      accountSettingsOpen: false
    };
  }

  toggle = (e) => {
    e.currentTarget.firstChild.classList.toggle('x');
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  accountSettingsToggle = () => {
    this.setState({
      accountSettingsOpen: !this.state.accountSettingsOpen
    });
  }

  handleDropdownLogout = () => {
    const callback = () => {
      this.props.history.push('/');
    }
    this.props.logout(callback);
  }

  handleDropdownHome = () => {
    this.props.history.push('/home');
  }

  render() {
    const { classes, account, loading } = this.props;
    return (
      <Navbar color='white' light expand='md'>
        <Notifications />
        <Container>
          <NavbarBrand to='/' tag={RRNavLink}>
            <HeaderBrandImage src={tigetPlusIconPath}/>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <HeaderNav className='ml-auto' navbar>
              <NavItem>
                <HeaderLink to='/help' tag={RRNavLink}>
                  ヘルプ
                </HeaderLink>
              </NavItem>
              <NavItem>
                <HeaderLink href='https://tiget.net'>
                  チケット販売・予約はTIGET
                </HeaderLink>
              </NavItem>
              {(() => {
                if (account.id) {
                  return(
                    <NavItem>
                      <HeaderLink>
                        <Dropdown isOpen={this.state.accountSettingsOpen} toggle={this.accountSettingsToggle}>
                          <DropdownToggle
                            tag="span"
                            onClick={this.accountSettingsToggle}
                            data-toggle="dropdown"
                            aria-expanded={this.state.accountSettingsOpen}>
                            {account.name}
                          </DropdownToggle>
                          <AccountSettingsDropdownMenu>
                            <div onClick={this.handleDropdownHome}>
                              <FaHome />
                              <span>ホーム</span>
                            </div>
                            <hr/>
                            <div onClick={this.handleDropdownLogout}>
                              <FaSignOutAlt />
                              <span>ログアウト</span>
                            </div>
                          </AccountSettingsDropdownMenu>
                        </Dropdown>
                      </HeaderLink>
                    </NavItem>
                  );
                }
              })()}
            </HeaderNav>
          </Collapse>
        </Container>
        <Loading show={loading.show}/>
      </Navbar>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Header);

/*
120行目に追加予定
<div>
  <FaCog />
  <span>アカウント設定</span>
</div>
<hr/>
*/
