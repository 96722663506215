// modules
import React       from 'react';
import { connect } from 'react-redux';
import compose     from 'recompose/compose';
import { Redirect }    from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { notify } from 'react-notify-toast';

// actions
import { login, resetPassword } from '../../actions/account';
import { clearError } from '../../actions/error';
import { getPickups } from '../../actions/users';

// components
import Header from '../containers/Header';
import LoginForm from '../presentionals/top/LoginForm';
import Footer from '../presentionals/Footer';
import ResetPasswordModal from '../presentionals/top/ResetPasswordModal';

// styles
import {
  TopBackground,
  PickupPanel,
  PickupElement
} from '../../styles/top';

// utils
import { mailRegEx } from '../../utils/regExpression';
import { syncUrl } from '../../utils/common';

import {
  tigetPlusIntroductionPath,
  tigetPlusIntroductionBackgroundPath
} from '../../utils/assets.js.erb'

function mapStateToProps(state) {
  return {
    account: state.account,
    error: state.error,
    users: state.users
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearError:() => dispatch(clearError()),
    login:(params, callback) => dispatch(login(params, callback)),
    resetPassword:(params, callback) => dispatch(resetPassword(params, callback)),
    getPickups:(params, callback) => dispatch(getPickups(params, callback))
  }
}

class Top extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      resetPasswordModal: false,
      sendMailMessage: '',
      invalidMail: false
    };
  }

  componentDidMount(){
    this.props.getPickups();
  }

  componentDidUpdate(prevProps, prevState){
    const prevError = prevProps.error;
    const currentError = this.props.error;
    if (prevError.statusCode !== currentError.statusCode) {
      switch(currentError.statusCode){
        case 0:
          break;
        case 401:
          notify.show('メールアドレスかパスワードが違います', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
          break;
        case 500:
          notify.show('サーバーエラーが発生しました。しばらく時間を置いてお試しください。', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
        default:
          break;
      }
    }
  }

  /**
   * ログインSubmit用handle
   * @type {void}
   */
  handleSubmit = (values) => {
    const callback = () => {
      this.props.clearError();
    }
    this.props.login(values, callback);
  }

  /**
   * パスワードリセットSubmit用handle
   * @type {void}
   */
  handleSubmitResetPassword = () => {
    const email = document.getElementById('reset-password-email').value;
    if (!mailRegEx(email)) {
      this.setState({
        sendMailMessage: '',
        invalidMail: true
      });
      return;
    }
    const callback = (res) => {
      this.setState({
        sendMailMessage: email,
        invalidMail: false
      });
    }
    this.props.resetPassword({email: email}, callback);
  }

  /**
   * パスワードリセットモーダルToggle
   * @type {void}
   */
  resetPasswordModalToggle = () => {
    this.setState({
      resetPasswordModal: !this.state.resetPasswordModal,
      sendMailMessage: '',
      invalidMail: false
    });
  }

  handleOnClickPickup = (e) => {
    const tigetUserId = e.currentTarget.getAttribute('data-tiget-user-id');
    window.open(
      syncUrl()
      + 'users/'
      + tigetUserId
    );
  }

  render() {
    const { account, error, users } = this.props;
    const { handleOnClickPickup } = this;
    if(account.id){
      return <Redirect to={'/home'} />
    }else{
      var pickupElements = [];
      users.forEach(function(user){
        pickupElements.push(
          <PickupElement
            key={user.id}
            xs='6'
            sm='6'
            md='3'
            onClick={handleOnClickPickup}
            data-tiget-user-id={user.tiget_user_id}>
            <img src={user.icon}></img>
            <p className='pickup-title'>{user.title}</p>
            <p className='pickup-sub-title'>{user.sub_title}</p>
          </PickupElement>
        );
      });
      return(
        <div style={{background: 'white'}}>
          <div className='content'>
            <Header history={this.props.history}/>
            <TopBackground style={{background: 'url(' + tigetPlusIntroductionBackgroundPath + ')'}}>
              <img src={tigetPlusIntroductionPath} />
            </TopBackground>
            <Container>
              <Row>
                <Col>
                  <LoginForm
                    onSubmit={this.handleSubmit}
                    onClickForgotPassword={this.resetPasswordModalToggle}/>
                </Col>
              </Row>
              <Row>
                <PickupPanel>
                  <p className='pickup-caption'>アーティスト活動を、一瞬で</p>
                  <Row>
                    {pickupElements}
                  </Row>
                </PickupPanel>
              </Row>
            </Container>
          </div>
          <Footer/>
          <ResetPasswordModal
            isOpen={this.state.resetPasswordModal}
            toggle={this.resetPasswordModalToggle}
            sendMailMessage={this.state.sendMailMessage}
            invalidMail={this.state.invalidMail}
            handleSubmitResetPassword={this.handleSubmitResetPassword}/>
        </div>
      );
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Top);
