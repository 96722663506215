// modules
import styled from 'styled-components';
import { Col } from 'reactstrap';

// styles
import { appColors } from './app';

export const UpdatePasswordFormBox = styled(Col)`
  height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 30px 0;

  & > form {
    width: 80%;
  }

  & > form > label {
    font-weight: bold;
    color: ${appColors.main};
    margin-bottom: 20px;
  }

  & > form > button {
    width: 80%;
    margin-left: 10%;
    border-radius: 0;
    background: ${appColors.main};
    margin-top: 10px;
    border: thin solid ${appColors.main};

    &:hover {
      background: white;
      color: ${appColors.main};
      border: thin solid ${appColors.main};
    }
  }
`;

export const ResetPasswordPanel = styled(Col)`
  background: white;
  margin-top: 100px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
`;
