// modules
import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator([
  'ERROR_RESPONSE',
  'CLEAR_ERROR',
]);

export function clearError(){
  return {
    type: actionTypes.CLEAR_ERROR,
  }
}

export function errorResponse(error){
  return {
    type: actionTypes.ERROR_RESPONSE,
    error: error,
  }
}
