/**
 * 同期URL取得
 */
export function syncUrl() {
  switch (location.host) {
    case 'tiget-plus-staging.herokuapp.com':
      return 'https://staging.tiget.net/';
    case 'plus.tiget.net':
      return 'https://tiget.net/';
    default:
      // ローカルで連携テストをする場合はここを変更してください
      return 'https://staging.tiget.net/';

  }
}
