// modules
import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerReducer, routerMiddleware, push } from 'react-router-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer as formReducer }  from 'redux-form';

// components
import Top from './components/containers/Top';
import Home from './components/containers/Home';
import PrivacyPolicy from './components/presentionals/PrivacyPolicy';
import UserPolicy from './components/presentionals/UserPolicy';
import Help from './components/presentionals/Help';
// 暫定的にtiget側の運営会社を表示
// import Corp from './components/presentionals/Corp';
import ResetPassword from './components/containers/ResetPassword';
import NotFound from './components/presentionals/NotFound';
import PrivateRoute from './components/containers/PrivateRouter';

// stores
import { createBrowserHistory } from 'history'
import {initialState as errorState} from './reducers/error';
import errorReducer from './reducers/error';
import { initialState as accountState } from './reducers/account';
import accountReducer from './reducers/account';
import { initialState as pagesState } from './reducers/pages';
import pagesReducer from './reducers/pages';
import { initialState as typesState } from './reducers/types';
import typesReducer from './reducers/types';
import { initialState as supportsState } from './reducers/supports';
import supportsReducer from './reducers/supports';
import { initialState as loadingState } from './reducers/loading';
import loadingReducer from './reducers/loading';
import { initialState as usersState } from './reducers/users';
import usersReducer from './reducers/users';

// actions
import { loggedin } from './actions/account';
import { getSuports } from './actions/supports';

// style
import 'bootstrap/dist/css/bootstrap.min.css';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min';
import 'react-datepicker/dist/react-datepicker';

const reducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  error: errorReducer,
  account: accountReducer,
  pages: pagesReducer,
  types: typesReducer,
  supports: supportsReducer,
  loading: loadingReducer,
  users: usersReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_RESPONSE') {
    const { router, supports } = state;
    state = { router, supports };
  }
  return reducer(state, action);;
};

const states  = {
  error: errorState,
  account: accountState,
  pages: pagesState,
  types: typesState,
  supports: supportsState,
  loading: loadingState,
  users: usersState
}

const history = createBrowserHistory();
const logger  = createLogger();
const router  = routerMiddleware(history);
const middleware = process.env.NODE_ENV !== 'production' ? applyMiddleware(thunk, logger, router) : applyMiddleware(thunk, router);
const store = createStore(rootReducer, states, middleware);

const render = (Component) => ({ history, location, match }) => {
  window.scrollTo(0, 0);
  return <Component history={history} location={location} match={match} />;
};

const MainApp = props => {
  return(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path='/' render={render(Top)} />
          <Route exact path='/privacy_policy' render={render(PrivacyPolicy)} />
          <Route exact path='/user_policy' render={render(UserPolicy)} />
          <Route exact path='/help' render={render(Help)} />
          <Route exact path='/reset_password' render={render(ResetPassword)} />
          <PrivateRoute exact path='/home' component={Home} redirectTo='/' history={history} />
          <Route component={NotFound} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.body.appendChild(document.createElement('app'));
  const root = createRoot(container);
  root.render(<MainApp />);
})

//ログインチェック
store.dispatch(getSuports(() => { /* 何か必要であれば */ }));
store.dispatch(loggedin(() => { /* 何か必要であれば */ }));
