// modules
import styled from 'styled-components';
import { Col } from 'reactstrap';

// styles
import { appColors } from './app';

export const StyledHelp = styled.div`
  margin: 60px 10px 40px 10px;
  font-size: 14px;
  background: white;
  padding: 40px 10%;
  border-radius: 5px;
  width: 100%;

  & > h2 {
    font-size: 22px;
    font-weight: bold;
    border-bottom: solid 1px ${appColors.main};
    padding-bottom: 40px;
    margin-bottom: 60px;
    text-align: center;
  }

  & > .help-element {
    & > h3 {
      color: black;
      font-size: 16px;
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    & > .help-item-element {
      & > .help-collapse-toggle {
        cursor: pointer;
        & > .help-collapse-toggle-arrow-down {
          position: relative;
          display: inline-block;
          padding-left: 20px;
          &:after{
            content: '';
            width: 16px;
            height: 16px;
            border: 0px;
            border-top: solid 2px ${appColors.accent};
            border-right: solid 2px ${appColors.accent};
            -ms-transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -12px;
          }
        }
        & > .help-collapse-toggle-arrow-up {
          position: relative;
          display: inline-block;
          padding-left: 20px;
          &:after{
            content: '';
            width: 16px;
            height: 16px;
            border: 0px;
            border-top: solid 2px ${appColors.accent};
            border-right: solid 2px ${appColors.accent};
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -6px;
          }
        }
      }
      & > .help-collapse {
        margin-top: 40px;
        white-space: pre-wrap;
      }
    }
  }
`;
