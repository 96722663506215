// actions
import {actionTypes} from '../actions/account';

export const initialState = {
  /**
   * TODO : ログインチェック後の401の場合にidをnullに変更し再描画を走らせる実装
   * ! 現状nullにはできるが再描画が走らない !
   * unconfirmed : undefined
   * unauthorized: null
   */
  id: undefined,
  email: null,
  provider: null,
  uid: null,
  tiget_user_id: null
}

export default function reducer(state = {}, action) {
  const { type } = action;
  switch (type) {
    case actionTypes.LOGIN_RESPONSE:
    case actionTypes.LOGGEDIN_RESPONSE:
    case actionTypes.UPDATE_PASSWORD_RESPONSE:
      return Object.assign({}, state, action.payload);
    case actionTypes.LOGOUT_RESPONSE:
      return Object.assign({}, initialState, {id: null});
    default:
      return state;
  }
}
