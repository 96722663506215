// modules
import React from 'react';
import { Container, Row } from 'reactstrap';

// components
import Header from '../containers/Header';
import Footer from '../presentionals/Footer';

// styles
import { StyledPolicy } from '../../styles/policy';

class PrivacyPolicy extends React.Component {
  render() {
    return(
      <div>
        <div className='content'>
          <Header history={this.props.history}/>
          <Container>
            <Row>
              <StyledPolicy>
                <h2>プライバシーポリシー</h2>
                <h3>第１条 （個人情報の収集について）</h3>
                <p>
                  個人情報とは、個人に関する情報であり、当該情報に含まれる氏名、
                  生年月日その他の記述等により特定の個人を識別することができるものを指します。
                  株式会社grabss（以下、「弊社」）の運営する各種インターネットサービス（以下「サービス」）は、
                  個人情報を収集することがあります。弊社はお客様の個人情報保護の重要性を深く認識し、
                  お客様の個人情報保護に最大限の注意を払います。
                  ここに弊社としてのプライバシーポリシー（個人情報保護に関する基本方針）を公開します。
                  弊社サービスをご利用される際には、「プライバシーポリシー」を熟読の上、
                  同意していただく必要があります。同意いただけない場合は、本サービスへのアクセスおよび本サービスのご利用はお断り致します。
                </p>
                <h3>第２条 （個人情報の利用について）</h3>
                <p>
                  弊社は、個人情報を以下の利用目的の達成に必要な範囲内で利用します。
                  以下に定めのない目的で個人情報を利用する場合、あらかじめご本人の同意を得た上で行ないます。
                </p>
                <ol>
                  <li>
                    お客様管理、本人確認、認証サービスのため
                  </li>
                  <li>
                    お客様が投稿した各種情報の掲載
                  </li>
                  <li>
                    お客様の利用情報（お客様がご利用になった本サービスの内容、ご利用時間、
                    ご利用回数、本サービス利用時の挙動、IPアドレス、位置情報などのご利用内容・履歴・状況に関する情報）
                  </li>
                  <li>
                    弊社または弊社の提携先でお客様登録を必要とするサービスを利用するにあたってのお客様登録等作業の簡素化
                  </li>
                  <li>
                    各種お問い合せ、アフターサービス等の対応
                  </li>
                  <li>
                    本サービスの運営上必要な事項の通知（電子メールによるものを含むものとします。）
                  </li>
                  <li>
                    キャンペーンやアンケート等の実施
                  </li>
                  <li>
                    メールマガジンやメッセージングサービスでの送信
                  </li>
                  <li>
                    マーケティングデータの調査・分析、新たなサービス開発、弊社サービスの改善に役立てるため
                  </li>
                  <li>
                    ポイント等のサービスの提供
                  </li>
                  <li>
                    システムの維持、不具合対応のため
                  </li>
                  <li>
                    弊社の提携先に提供する統計資料作成
                  </li>
                  <li>
                    契約や法律等に基づく権利の行使や義務の履行
                  </li>
                  <li>
                    その他弊社の各サービスにおいて個別に定める目的のため
                  </li>
                </ol>
                <h3>第３条 （個人情報の第三者への提供・開示）</h3>
                <p>
                  弊社は、以下に定める場合には、個人情報を第三者に提供することができるものとします。
                </p>
                <ol>
                  <li>
                    本人の同意がある場合
                  </li>
                  <li>
                    裁判所、検察庁、警察、税務署、弁護士会またはこれらに
                    準じた権限を持つ機関から、個人情報の開示を求められた場合
                  </li>
                  <li>
                    保険金請求のために保険会社に開示する場合
                  </li>
                  <li>
                    コンテンツの提供、その他付随するサービス等の提供のために
                    お客様の注文情報または申し込み情報を転送する場合
                  </li>
                  <li>
                    代金の決済を委託する事業者に開示する場合
                  </li>
                  <li>
                    弊社または第三者の広告の配信または表示する場合
                  </li>
                  <li>
                    弊社が行う業務の全部または一部を第三者に対して秘密保持義務を負う者に対して開示する場合
                  </li>
                  <li>
                    弊社のサービス提供および権利行使に必要な場合
                  </li>
                  <li>
                    合併、営業譲渡その他の事由による事業の承継の際に、事業を承継する者に対して開示する場合
                  </li>
                  <li>
                    個人情報保護法その他の法令により認められた場合
                  </li>
                </ol>
                <h3>第４条 （個人情報の開示・訂正等について）</h3>
                <p>
                  弊社は、ご本人から自己の個人情報についての開示の請求がある場合、
                  速やかに開示を致します。その際、ご本人であることが確認できない場合には、
                  開示に応じません。個人情報の内容に誤りがあり、ご本人から訂正・追加・削除の請求がある場合、
                  調査の上、速やかにこれらの請求に対応致します。その際、ご本人であることが確認できない場合には、
                  これらの請求に応じません。弊社の個人情報の取り扱いにつきまして、上記の請求・お問い合わせ等ございましたら、
                  下記お問い合わせまでご連絡くださいますようお願い致します。
                </p>
                <h3>第5条 （未成年のお客様の情報について）</h3>
                <p>
                  お客様が未成年者の場合は、ご両親、保護者または後見人の方（以下「保護者様」といいます）
                  がプライバシーポリシーおよび利用規約等の内容に同意され、
                  お客様がプライバシーポリシーおよび利用規約等に基づき本サービスを
                  ご利用いただくことについて包括的に同意されることが必要となります。
                </p>
                <h3>第6条 （免責）</h3>
                <p>
                  弊社及び弊社サービスでは、お客様のID及びパスワードの管理については関与いたしません。
                  お客様の不注意によりID及びパスワードが第三者に利用された場合は、
                  ご登録いただいている個人情報を閲覧される可能性がございますので、
                  ご利用にあたっては、使用および管理について責任を負うと共に、
                  使用上の過誤または第三者による不正使用等について十分ご注意ください。
                </p>
                <h3>第7条 （プライバシーポリシーの変更）</h3>
                <p>
                  本プライバシーポリシーの内容は変更されることがあります。
                  変更後の本プライバシーポリシーについては、弊社が別途定める場合を除いて、
                  当サイトに掲載した時から効力を生じるものとします。
                </p>
                <div className='bottom-block'>
                  <p>平成30年9月10日制定</p>
                  <br/>
                  <p>株式会社grabss</p>
                  <p>お問い合わせ：info@plus.tiget.net</p>
                  <p style={{fontWeight: 'bold'}}>お問い合わせ時のご注意</p>
                  <p style={{fontStyle: 'italic'}}>受付時間：土・日・祝日および、弊社休業日を除く9時から17時</p>
                  <p style={{fontStyle: 'italic'}}>お問い合わせの内容によっては、回答できない場合や、回答までに時間がかかる場合があります。</p>
                </div>
              </StyledPolicy>
            </Row>
          </Container>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default PrivacyPolicy;
