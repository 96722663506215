import { actionTypes as accountActionTypes } from '../actions/account';
import { actionTypes as errorActionTypes } from '../actions/error';
import { actionTypes as pagesActionTypes } from '../actions/pages';

export const initialState = {
  show: false
}

export default function reducer(state = {}, action) {
  const { type } = action;
  switch(type) {
    case accountActionTypes.LOGIN_REQUEST:
    case pagesActionTypes.GET_PAGES_REQUEST:
    case pagesActionTypes.PREVIEW_PAGE_REQUEST:
    case pagesActionTypes.PUBLISH_PAGE_REQUEST:
    case pagesActionTypes.ROLLBACK_PAGE_REQUEST:
      return Object.assign({}, state, {
        show: true
      });
    case pagesActionTypes.UPDATE_PAGE_REQUEST:
      var isLoading = false;
      if (typeof action.payload[Symbol.iterator] === 'function') {
        for (var key of action.payload.keys()) {
          isLoading = key === 'page[header]' || key === 'page[icon]';
          if (isLoading) { break; }
        }
      } else {
        isLoading = 'remove_icon' in action.payload.page || 'remove_header' in action.payload.page;
      }
      if (isLoading) {
        return Object.assign({}, state, {
          show: true
        });
      } else {
        return state;
      }
    case pagesActionTypes.UPDATE_PAGE_COMPONENT_REQUEST:
      if (typeof action.payload[Symbol.iterator] === 'function') {
        for (var value of action.payload) {
          isLoading = value[0] === 'component[items_attributes][thumbnail]';
          if (isLoading) { break; }
        }
      } else {
        isLoading = 'items_attributes' in action.payload.component && 'remove_thumbnail' in action.payload.component.items_attributes;
      }
      if (isLoading) {
        return Object.assign({}, state, {
          show: true
        });
      } else {
        return state;
      }
    case errorActionTypes.ERROR_RESPONSE:
    case accountActionTypes.LOGIN_RESPONSE:
    case pagesActionTypes.GET_PAGES_RESPONSE:
    case pagesActionTypes.PREVIEW_PAGE_RESPONSE:
    case pagesActionTypes.PUBLISH_PAGE_RESPONSE:
    case pagesActionTypes.ROLLBACK_PAGE_RESPONSE:
    case pagesActionTypes.UPDATE_PAGE_RESPONSE:
    case pagesActionTypes.UPDATE_PAGE_COMPONENT_RESPONSE:
      return Object.assign({}, state, {
        show: false
      });
    default:
      return state;
  }
}
