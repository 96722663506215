// modules
import styled from 'styled-components';
import Textarea from 'react-textarea-autosize';
import { Col, Button, Input, Label } from 'reactstrap';
import { FaTwitter, FaFacebook, FaYoutube, FaList } from 'react-icons/fa';

// styles
import { appColors } from './app';

// アイコンカラーリスト
const iconColors = {
  twitter   : '#55acee',
  facebook  : '#3b5998',
  youtube   : '#cd201f',
  information: appColors.main
}

// コンポーネントアイコン共通定義
const componentDefaultIcon = {
  height: '20px',
  width: '20px',
  marginTop: '10px'
};

export const HeaderImgBox = styled.div`
  position: relative;
  background: black;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 5px ${appColors.main} inset;
  }
`;

export const HeaderImg = styled.img`
  width: 100%;
  height: auto;
  opacity: .5;
`;

export const HeaderText = styled.p`
  color: white;
  font-size: x-large;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  text-align: center;
`;

export const IconImgBox = styled.div`
  position: relative;
  background: black;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: -70px;
  border: 3px solid white;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    margin-top: -40px;
    width: 80px;
    height: 80px;
  }

  &:hover {
    box-shadow: 0 0 0 3px ${appColors.main};
  }
`;

export const IconImg = styled.img`
  width: 100%;
  height: 100%;
  opacity: .5;
  border-radius: 50%;
  object-fit: cover;
`;

export const IconText = styled.p`
  color: white;
  font-size: x-small;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  text-align: center;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const TitleInput = styled.input`
  color: black;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 0;
  border: none;
  outline: none;
  background: transparent;

  &:focus+#title-underline, &:hover+#title-underline {
    transform: scale(1);
  }
`;

export const SubTitleInput = styled.input`
  width: 100%;
  font-size: 10px;
  text-align: center;
  padding-top: 0;
  border: none;
  outline: none;
  color: ${appColors.main};
  background: transparent;

  &:focus+#sub-title-underline, &:hover+#sub-title-underline {
    transform: scale(1);
  }
`;

export const UnderLine = styled.hr`
  border: 1px ridge ${appColors.main};
  margin: 0;
  width: 100%;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
`

export const DescriptionTextArea = styled(Textarea)`
  color: black;
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  background: transparent;

  &:focus+#description-underline, &:hover+#description-underline {
    transform: scale(1);
  }
`

// ここからComponents

export const ComponentHeader = styled(Col)`
  height: 30px;
  font-size: 14px;
  color: ${appColors.main};
  background: ${appColors.sub};
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ComponentDeleteButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 20px;
  border-radius: 50%;
  z-index: 1000;
`

export const ComponentEditButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 60px;
  border-radius: 50%;
`
export const ComponentPreviwButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 100px;
  border-radius: 50%;
`

export const ComponentBase = styled(Col)`
  margin-bottom: 10px;
  background: transparent;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: ${({theme})=> theme.corsor || 'move'};
  }
`

export const ComponentContent = styled(Col)`
  height: 450px;
  @media screen and (max-width: 767px) {
    height: ${({theme})=> theme.spHeight || '450px'};
  }
  width: 100%;
  background: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`

export const ComponentSeparator = styled(Col)`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
  border-left: solid 5px ${appColors.main};
  padding-left: 15px;
  position: relative;

  &:before {
    content: '';
    width: 3px;
    height: 100%;
    border-left: solid 3px ${appColors.main};
    position: absolute;
    left: 3px;
    bottom: 0px;
  }

  & > input {
    width: 100%;
    font-size: 16px;
    text-align: left;
    border: none;
    outline: none;
    color: black;
    background: transparent;
    &:focus+#separator-name-underline, &:hover+#separator-name-underline {
      transform: scale(1);
    }
  }
`

export const ComponentCreate = styled(Button)`
  height: 450px;
  width: 100%;
  background: transparent;
  border: thick double white;
`

export const ComponentIconTwitter = styled(FaTwitter)`
  height: ${componentDefaultIcon.height};
  width: ${componentDefaultIcon.width};
  margin-top: ${componentDefaultIcon.marginTop};
  color: ${iconColors.twitter};
`

export const ComponentIconFacebook = styled(FaFacebook)`
  height: ${componentDefaultIcon.height};
  width: ${componentDefaultIcon.width};
  margin-top: ${componentDefaultIcon.marginTop};
  color: ${iconColors.facebook};
`

export const ComponentIconYoutube = styled(FaYoutube)`
  height: ${componentDefaultIcon.height};
  width: ${componentDefaultIcon.width};
  margin-top: ${componentDefaultIcon.marginTop};
  color: ${iconColors.youtube};
`

export const ComponentNameInput = styled.input`
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  outline: none;
  color: ${appColors.main};
  background: transparent;
  &:focus+#component-name-underline, &:hover+#component-name-underline {
    transform: scale(1);
  }
`

export const ComponentNamePreview = styled(Col)`
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${appColors.main};
  background: transparent;

  & > span {
    color: white;
    padding: 5px;
    margin-left: 10px;
    border-radius: 1px;
    background: ${appColors.main};
  }
`

export const ComponentDatasourceLabel = styled(Label)`
  width: 100%;
  text-align: center;
  margin-top: 120px;
  font-size: 14px;
  color: gray;

  & > span {
    color: white;
    padding: 5px;
    margin-left: 10px;
    border-radius: 1px;
    background: ${appColors.main};
  }
`;

export const ComponentDatasourceInput = styled(Input)`
  width: 100%;
  font-size: 14px;
  background: transparent;
`;

export const ComponentItemList = styled.div`
  width: 100%;
  height: ${({theme})=> theme.height};
  margin-top: ${({theme})=> theme.marginTop};
  padding-bottom: 20px;
  background: transparent;
  overflow: scroll;
`;

export const ComponentItem = styled.div`
  background: transparent;
  text-align: left;
  margin-bottom: 20px;
`;

export const ComponentItemStartDateInput = styled(Input)`
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  width: 160px;
  border: none;
`;

export const ComponentItemStartDatePreview = styled.p`
  padding: 0;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
`;

export const ComponentItemCreateButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background: ${appColors.main};
  border: 2px solid ${appColors.main};
  color: white;
  border-radius: 0;

  &:hover {
    background: white;
    border: 2px solid ${appColors.main};
    color: ${appColors.main};
  }
`

export const ThumbnailBox = styled.div`
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  cursor: pointer;
  background: black;

  &:hover {
    box-shadow: 0 0 0 2px ${appColors.main} inset;
  }
`;

export const ThumbnailDeleteButton = styled(Button)`
  position: absolute;
  left: 40px;
  border-radius: 50%;
  z-index: 1000;
`;

export const Thumbnail = styled.img`
  height: 70px;
  width: 70px;
  object-fit: contain;
  opacity: ${({theme})=> theme.opacity};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ComponentItemTextBlock = styled.div`
  width: calc(100% - 80px);
  margin-left: 10px;
  display: inline-block;
  vertical-align: top;
  background: transparent;
`;

export const ComponentItemNameInput = styled.input`
  color: black;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  border: none;
  outline: none;
  background: transparent;

  &:focus+#item-name-underline, &:hover+#item-name-underline {
    transform: scale(1);
  }
`;

export const ComponentItemDatePickerInput = styled.input`
  margin-top: 10px;
  color: black;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  border: none;
  outline: none;
  background: transparent;

  &:focus+#item-date-picker-underline, &:hover+#item-date-picker-underline {
    transform: scale(1);
  }
`;

export const ComponentItemNameSelectArrow = styled.span`
  width: 50%;
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border: 0px;
    border-bottom: solid 2px ${appColors.main};
    border-right: solid 2px ${appColors.main};
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -4px;
    pointer-events: none;
  }
`;

export const ComponentItemNameSelect = styled.select`
  color: black;
  font-size: 12px;
  font-weight: normal;
  border: none;
  outline: none;
  background: transparent;
  height: 24px;
  width: 100%;
  padding-left: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  &:hover+#item-select-underline {
    transform: scale(1);
  }
`;

export const ComponentItemNamePreview = styled.span`
  display: block;
  margin-bottom: 0;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  background: transparent;
`;

export const ComponentItemDataSouceInput = styled(Textarea)`
  color: black;
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
  background: transparent;

  &:focus+#item-data-source-underline, &:hover+#item-data-source-underline {
    transform: scale(1);
  }
`;

export const ComponentItemDataSoucePreview = styled.a`
  margin-top: 10px;
  display:block;
  white-space: pre-wrap;
  width: 100%;
  font-size: 12px;
  background: transparent;
  color: #516DC1;
`;

export const ComponentItemButton = styled(Button)`
  background: ${appColors.main};
  border: 2px solid ${appColors.main};
  color: white;
  border-radius: 0;
  margin-top: 20px;
  margin-right: 10px;
  &:hover {
    background: white;
    border: 2px solid ${appColors.main};
    color: ${appColors.main};
  }
`;

export const PhotoImgBox = styled.div`
  position: relative;
  background: white;
`;

export const PhotoImg = styled.img`
  object-fit: contain;
  height: 450px;
  width: 100%;
`;

export const ContactInput = styled.input`
  outline: none;
  background: #F9FAFB;
  color: #9A9B9C;
  border: thin solid #DDDFE0;
  font-size: 16px;
  text-indent: 5px;
  margin-top: 20px;
  height: 32px;
  width: 100%;
`;

export const ContactTextArea = styled.textarea`
  outline: none;
  background: #F9FAFB;
  color: #9A9B9C;
  border: thin solid #DDDFE0;
  font-size: 16px;
  text-indent: 5px;
  line-height: 40px;
  margin-top: 20px;
  height: 140px;
  width: 100%;
  resize: none;
`;

export const ContactButton = styled(Button)`
  background: ${appColors.main};
  font-size: 16px;
  margin-top: 20px;
  margin-left: 10%;
  margin-bottom: 10px;
  border: none;
  height: 50px;
  width: 80%;
  pointer-events: none;
  &:hover {
    background: ${appColors.main};
  }
`;

export const ContactCaption = styled.p`
  color: #9A9B9C;
  font-size: 10px;
  text-align: center;
`;

export const TypeGroupUl = styled.ul`
  & > li {
    cursor: pointer;
  }

  & > li.active {
    background: ${appColors.main};
  }
`;

export const PageButton = styled(Button)`
  width: 200px;
  margin: 20px;
  border-radius: 0;
  background: ${appColors.main};
  border: thin solid ${appColors.main};

  &:hover {
    background: white;
    color: ${appColors.main};
    border: thin solid ${appColors.main};
  }
`;
