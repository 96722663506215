// modules
import React from 'react';

// styles
import { OverlayLoading } from '../../styles/app';

class Loading extends React.Component {

  render() {
    const { show } = this.props;
    if (show) {
      return(
        <OverlayLoading>
          <div className='pixel-spinner'>
            <div className='pixel-spinner-inner'></div>
          </div>
          <div className='loading'>
            <span data-text='T'>T</span>
            <span className='reverse' data-text='I'>I</span>
            <span data-text='G'>G</span>
            <span data-text='E'>E</span>
            <span data-text='T' style={{marginRight: '10px'}}>T</span>
            <span className='reverse' data-text='p'>p</span>
            <span className='reverse' data-text='l'>l</span>
            <span className='reverse' data-text='u'>u</span>
            <span className='reverse' data-text='s'>s</span>
          </div>
        </OverlayLoading>
      );
    } else {
      return null;
    }
  }
}

export default Loading;
