// modules
import React from 'react';
import { FaTimes } from 'react-icons/fa';

// styles
import {
  ThumbnailBox,
  Thumbnail,
  HiddenInput,
  ThumbnailDeleteButton
} from '../../../styles/home';

import { iconNoImagePath } from '../../../utils/assets.js.erb'

class ThumbnailImage extends React.Component {

  render() {
    const {
      item,
      handleChangeThumbnail,
      handleDeleteThumbnail,
      isPreview
    } = this.props;
    if (isPreview) {
      return (<Thumbnail theme={{opacity: '1'}} src={item.thumbnail} />);
    } else {
      return (
        <ThumbnailBox onClick={(event) => { document.getElementById('thumbnail-' + item.id).click() } }>
          {(() => {
            if (item.thumbnail) {
              return(
                <ThumbnailDeleteButton
                  data-item-id={item.id}
                  size='sm'
                  onClick={handleDeleteThumbnail}>
                  <FaTimes/>
                </ThumbnailDeleteButton>
              )
            }
          })()}
          <Thumbnail theme={{opacity: '0.5'}} src={item.thumbnail || iconNoImagePath} />
          <HiddenInput
            data-item-id={item.id}
            type='file'
            accept='image/*'
            id={'thumbnail-' + item.id}
            onChange={handleChangeThumbnail} />
        </ThumbnailBox>
      );
    }
  }
}

export default ThumbnailImage;
