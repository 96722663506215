// modules
import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  FaTwitter,
  FaFacebook,
  FaYoutube,
  FaList,
  FaArrowsAltH,
  FaImage,
  FaUserCircle
} from 'react-icons/fa';

// styles
import { TypeGroupUl } from '../../../styles/home';
import {
  ModalAppButton,
  ModalCancelButton
} from '../../../styles/app';

class CreateModal extends React.Component {

  render() {
    const {
      isOpen,
      toggle,
      types,
      isRestricted,
      handleSelectedComponentType,
      handleCreateComponentConfirmed
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>新しいアイテムを追加</ModalHeader>
        <ModalBody>
        <p>アイテムはカレンダーとタイトルを除き６件まで登録できます。</p>
        <TypeGroupUl className='list-group'>
          {types.map((type) => {
            switch (type.name) {
              case 'twitter':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className={'list-group-item ' + (isRestricted ? 'disabled' : '')}
                    onClick={isRestricted ? null : handleSelectedComponentType}>
                    <FaTwitter/> Twitter
                  </li>
                )
              case 'facebook':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className={'list-group-item ' + (isRestricted ? 'disabled' : '')}
                    onClick={isRestricted ? null : handleSelectedComponentType}>
                    <FaFacebook/> Facebook
                  </li>
                )
              case 'youtube':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className={'list-group-item ' + (isRestricted ? 'disabled' : '')}
                    onClick={isRestricted ? null : handleSelectedComponentType}>
                    <FaYoutube/> Youtube
                  </li>
                )
              case 'information':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className={'list-group-item ' + (isRestricted ? 'disabled' : '')}
                    onClick={isRestricted ? null : handleSelectedComponentType}>
                    <FaList/> Information
                  </li>
                )
              case 'separator':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className='list-group-item'
                    onClick={handleSelectedComponentType}>
                    <FaArrowsAltH/> Title
                  </li>
                )
              case 'photo':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className={'list-group-item ' + (isRestricted ? 'disabled' : '')}
                    onClick={isRestricted ? null : handleSelectedComponentType}>
                    <FaImage/> Photo
                  </li>
                )
              case 'contact':
                return (
                  <li
                    data-type-id={type.id}
                    key={type.id}
                    className={'list-group-item ' + (isRestricted ? 'disabled' : '')}
                    onClick={isRestricted ? null : handleSelectedComponentType}>
                    <FaUserCircle/> Contact
                  </li>
                )
            }
          })}
        </TypeGroupUl>
        </ModalBody>
        <ModalFooter>
          <ModalAppButton onClick={handleCreateComponentConfirmed}>OK</ModalAppButton>
          <ModalCancelButton onClick={toggle}>キャンセル</ModalCancelButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateModal;
