// modules
import axios from 'axios';
import cookie from 'js-cookie';

// constants
import {API_BASE_PATH} from './constants';

const http = axios.create({
      baseURL: API_BASE_PATH,
    });

http.interceptors.request.use((config) => {
    config.headers['access-token'] = cookie.get('access-token');
    config.headers['client'] = cookie.get('client');
    config.headers['uid'] = cookie.get('uid');
    config.timeout = 30000;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

http.interceptors.response.use(function (response) {
    if( 'access-token' in response.headers &&
        'client' in response.headers &&
        'uid' in response.headers) {
      cookie.set('access-token', response.headers['access-token']);
      cookie.set('client',       response.headers['client']);
      cookie.set('uid',          response.headers['uid']);
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export function removeAuth(){
    cookie.remove('access-token');
    cookie.remove('client');
    cookie.remove('uid');
}

export default http;
