// modules
import React from 'react';
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  ModalAppButton,
  ModalCancelButton
} from '../../../styles/app';

class SettingLinkModal extends React.Component {

  render() {
    const { handleSettingConfirmed, isOpen, toggle, settingLinkItem } = this.props;
    const currentLinkValue = settingLinkItem ? settingLinkItem.link : null;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>リンク設定</ModalHeader>
        <ModalBody>
          <Input id='item-link' type='text' defaultValue={currentLinkValue}/>
        </ModalBody>
        <ModalFooter>
          <ModalAppButton onClick={handleSettingConfirmed}>OK</ModalAppButton>
          <ModalCancelButton onClick={toggle}>キャンセル</ModalCancelButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SettingLinkModal;
