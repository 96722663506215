// modules
import mirrorCreator from 'mirror-creator';

// axios
import http, {removeAuth} from '../http';

// actions
import {errorResponse, clearError} from '../actions/error';

export const actionTypes = mirrorCreator([
  'LOGIN_REQUEST',
  'LOGIN_RESPONSE',
  'LOGGEDIN_REQUEST',
  'LOGGEDIN_RESPONSE',
  'LOGOUT_REQUEST',
  'LOGOUT_RESPONSE',
  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_RESPONSE',
  'UPDATE_PASSWORD_REQUEST',
  'UPDATE_PASSWORD_RESPONSE'
]);

const API_PATH = 'auth';

//---------------------------------
// 非同期 Redux-Thunkアクション
//---------------------------------

//ログイン
export function login(params, callback){
  return dispatch => {
    dispatch(clearError());
    dispatch({type: actionTypes.LOGIN_REQUEST, payload: params});
    http.post(API_PATH + '/sign_in', params)
      .then(function (response) {
        dispatch({type: actionTypes.LOGIN_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

//ログインチェック
export function loggedin(callback){
  return dispatch => {
    dispatch({type: actionTypes.LOGGEDIN_REQUEST});
    http.get(API_PATH)
      .then(function (response) {
        dispatch({type: actionTypes.LOGGEDIN_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        dispatch({type: actionTypes.LOGGEDIN_RESPONSE, payload: { id: null }});
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
      });
  };
}

//ログアウト
export function logout(callback){
  return dispatch => {
    dispatch({type: actionTypes.LOGOUT_REQUEST});
    http.delete(API_PATH + '/sign_out')
      .then(function (response) {
        dispatch({type: actionTypes.LOGOUT_RESPONSE, payload: response.data});
        removeAuth();
        if(callback){
          callback();
        }
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch({type: actionTypes.LOGOUT_RESPONSE, payload: { id: null }});
        removeAuth();
        dispatch(errorResponse(error));
      });
  };
}

//パスワード変更申請
export function resetPassword(params, callback) {
  return dispatch => {
    dispatch({type: actionTypes.RESET_PASSWORD_REQUEST, payload: params});
    http.put('reset_password', params)
      .then(function (response) {
        dispatch({type: actionTypes.RESET_PASSWORD_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

//パスワード変更
export function updatePassword(params, callback) {
  return dispatch => {
    dispatch(clearError());
    dispatch({type: actionTypes.UPDATE_PASSWORD_REQUEST, payload: params});
    http.put('update_password', params)
      .then(function (response) {
        dispatch({type: actionTypes.UPDATE_PASSWORD_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}
