// modules
import React from 'react';
import {
  Container,
  Row,
  UncontrolledCollapse
} from 'reactstrap';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// components
import Header from '../../components/containers/Header';
import Footer from '../../components/presentionals/Footer';

// styles
import { StyledHelp } from '../../styles/help';

function mapStateToProps(state) {

  return {
    helps: state.supports.filter(function(item){
      return item.category === 'help';
    })
  }
}

class Help extends React.Component {

  /**
   * !!! UncontrolledCollapseについての注意 !!!
   * source   : reactstrap/src/UncontrolledCollapse.js
   * line     : 47
   * 該当記述  : e.preventDefault();
   *
   * onClickでarrowを変化させたいが
   * 上記の部分が問題でtogglerに指定した要素のonClickをスマホのみ拾えません。
   *
   * 通常のControllできるCollapseであれば問題なさそうだが、
   * help.itemsのstate管理がわからない。
   * 工数的にお勉強している時間がないのでかなり特殊な実装で対応しています。
   *
   * 以下概要
   * 1.togglerにしたい要素のidには何も指定せずにonClickを拾う
   * 2.非表示要素としてtoggerのidが指定された要素を記述しておく(不使用ですが、ないとエラー吐きます)
   * 3.onClickのハンドラの最後でUncontrolledCollapseインスタンスのstateを直接書き換える
   *
   */
  toggle = (e) => {
    const itemId = e.currentTarget.getAttribute('data-item-id');
    this.refs[itemId].classList.toggle('help-collapse-toggle-arrow-down');
    this.refs[itemId].classList.toggle('help-collapse-toggle-arrow-up');
    const uncontrolledCollapse = this.refs['toggler-' + itemId];
    uncontrolledCollapse.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  render() {
    const { helps } = this.props;
    const { toggle } = this;
    const helpElements = [];
    helps.forEach(function(help){
      const helpItemElements = [];
      help.items.forEach(function(item){
        helpItemElements.push(
          <div key={item.id} className='help-item-element'>
            <div data-item-id={item.id} className='d-flex justify-content-between help-collapse-toggle' onClick={toggle}>
              <div id={'toggler-' + item.id} style={{display: 'none'}} />
              <div className='align-self-center' style={{paddingRight: '40px'}}>
                {item.name}
              </div>
              <div className='align-self-center help-collapse-toggle-arrow-down' ref={item.id}>
              </div>
            </div>
            <UncontrolledCollapse
              toggler={'toggler-' + item.id}
              className='help-collapse'
              ref={'toggler-' + item.id}>
              {item.description}
            </UncontrolledCollapse>
            <hr/>
          </div>
        )
      })
      helpElements.push(
        <div key={help.id} className='help-element'>
          <h3>{help.name}</h3>
          {helpItemElements}
        </div>
      )
    })
    return(
      <div>
        <div className='content'>
          <Header history={this.props.history}/>
          <Container>
            <Row>
              <StyledHelp>
                <h2>ヘルプ</h2>
                {helpElements}
              </StyledHelp>
            </Row>
          </Container>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default compose(
  connect(mapStateToProps)
)(Help);
