// modules
import mirrorCreator from 'mirror-creator';

// axios
import http, {removeAuth} from '../http';

// actions
import {errorResponse, clearError} from '../actions/error';

export const actionTypes = mirrorCreator([
  'GET_PICKUPS_REQUEST',
  'GET_PICKUPS_RESPONSE'
]);

const API_PATH = 'users';

//ピックアップユーザー一覧取得
export function getPickups(params, callback){
  return dispatch => {
    dispatch(clearError());
    dispatch({type: actionTypes.GET_PICKUPS_REQUEST, payload: params});
    http.get(API_PATH + '/pickups', params)
      .then(function (response) {
        dispatch({type: actionTypes.GET_PICKUPS_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}
