// modules
import React from 'react';
import { Input } from 'reactstrap';
import { Row, Col } from 'reactstrap';

// styles
import { TitleInput, SubTitleInput, UnderLine, DescriptionTextArea } from '../../../styles/home';

class PageInformation extends React.Component {

  render() {
    const { page, handleBlurInput } = this.props;
    return (
      <Row>
        <Col sm={{ size: 8, offset: 2 }} xs='12'>
          <TitleInput data-param-name='title' type='text' defaultValue={page.title} onBlur={handleBlurInput} placeholder='タイトル'/>
          <UnderLine id='title-underline'/>
          <SubTitleInput data-param-name='sub_title' type='text' defaultValue={page.sub_title} onBlur={handleBlurInput} placeholder='サブタイトル'/>
          <UnderLine id='sub-title-underline'/>
          <DescriptionTextArea data-param-name='description' rows={1} defaultValue={page.description} onBlur={handleBlurInput} placeholder='概要'/>
          <UnderLine id='description-underline'/>
        </Col>
      </Row>
    );
  }
}

export default PageInformation;
