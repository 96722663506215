// modules
import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import { NavLink } from 'react-router-dom';

// styles
import {
  StyledFooterContainer,
  FooterLogoImage,
  FooterCopyright
} from '../../styles/app';

import { tigetPlusIconPath } from '../../utils/assets.js.erb'

class Footer extends React.Component {

  render() {
    const {} = this.props;
    return (
      <div>
        <div style={{ height: '60px' }}/>
          <StyledFooterContainer fluid>
            <Row>
              <Col xs='12'>
                <FooterLogoImage src={tigetPlusIconPath}/>
              </Col>
              <Col xs='12'>
                <ul>
                  <li>
                    <NavLink to='/help'>
                      ヘルプ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/user_policy'>
                      利用規約
                    </NavLink>
                  </li>
                  <li>
                    <a href='https://tiget.net/users/133'>
                      運営会社
                    </a>
                  </li>
                  <li>
                    <NavLink to='/privacy_policy'>
                      プライバシーポリシー
                    </NavLink>
                  </li>
                </ul>
              </Col>
              <Col xs='12' className='d-none d-sm-block'>
                <FooterCopyright>
                  Copyright (C) 2013 - { (new Date()).getFullYear() } grabss Inc. All rights reserved.
                </FooterCopyright>
              </Col>
              <Col xs='12' className='d-block d-sm-none'>
                <FooterCopyright>
                  Copyright (C) 2013 - { (new Date()).getFullYear() }
                </FooterCopyright>
                <FooterCopyright>
                  grabss corporation. All rights reserved.
                </FooterCopyright>
              </Col>
            </Row>
          </StyledFooterContainer>
      </div>
    );
  }
}

export default Footer;
