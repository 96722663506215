/**
 * メールアドレス正規表現
 */
export function mailRegEx(mail) {
  var mail_regex1 = new RegExp('(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*');
  var mail_regex2 = new RegExp('^[^\@]+\@[^\@]+$');
  if(mail.match(mail_regex1) && mail.match(mail_regex2)) {
      // 全角チェック
      if(mail.match(/[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/)) {
        return false;
      }
      // 末尾TLDチェック
      if(!mail.match( /\.[a-z]+$/)) {
        return false;
      }
      return true;
  } else {
      return false;
  }
}
