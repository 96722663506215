// modules
import mirrorCreator from 'mirror-creator';

// axios
import http from '../http';

import {errorResponse, clearError} from '../actions/error';

export const actionTypes = mirrorCreator([
  'GET_PAGES_REQUEST',
  'GET_PAGES_RESPONSE',
  'PREVIEW_PAGE_REQUEST',
  'PREVIEW_PAGE_RESPONSE',
  'PUBLISH_PAGE_REQUEST',
  'PUBLISH_PAGE_RESPONSE',
  'ROLLBACK_PAGE_REQUEST',
  'ROLLBACK_PAGE_RESPONSE',
  'UPDATE_PAGE_REQUEST',
  'UPDATE_PAGE_RESPONSE',
  'CREATE_PAGE_COMPONENT_REQUEST',
  'CREATE_PAGE_COMPONENT_RESPONSE',
  'UPDATE_PAGE_COMPONENT_REQUEST',
  'UPDATE_PAGE_COMPONENT_RESPONSE',
  'DELETE_PAGE_COMPONENT_REQUEST',
  'DELETE_PAGE_COMPONENT_RESPONSE'
]);

const API_PATH = 'pages/';

export function getPages(params){
  return dispatch => {
    dispatch({type: actionTypes.GET_PAGES_REQUEST, payload: params});
    http.get(API_PATH, params)
      .then(function (response) {
        dispatch({type: actionTypes.GET_PAGES_RESPONSE, payload: response.data});
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

export function updatePage(pageId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.UPDATE_PAGE_REQUEST, payload: params});
    http.put(API_PATH + pageId, params)
      .then(function (response) {
        dispatch({type: actionTypes.UPDATE_PAGE_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

export function previewPage(pageId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.PREVIEW_PAGE_REQUEST, payload: params});
    http.put(API_PATH + pageId + '/preview', params)
      .then(function (response) {
        dispatch({type: actionTypes.PREVIEW_PAGE_RESPONSE, payload: response.data});
        if(callback)callback(response.data);
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

export function publishPage(pageId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.PUBLISH_PAGE_REQUEST, payload: params});
    http.put(API_PATH + pageId + '/publish', params)
      .then(function (response) {
        dispatch({type: actionTypes.PUBLISH_PAGE_RESPONSE, payload: response.data});
        if(callback)callback(response.data);
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        if (error.response) {
          dispatch(errorResponse(error));
        } else {
          dispatch({type: actionTypes.PUBLISH_PAGE_RESPONSE, payload: { status: 408 }});
          if(callback)callback({ status: 408 });
        }
      });
  };
}

export function rollbackPage(pageId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.ROLLBACK_PAGE_REQUEST, payload: params});
    http.put(API_PATH + pageId + '/rollback', params)
      .then(function (response) {
        dispatch({type: actionTypes.ROLLBACK_PAGE_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

const API_PATH_PAGE_COMPONENT = '/components/';

export function createPageComponent(pageId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.CREATE_PAGE_COMPONENT_REQUEST, payload: params});
    http.post(API_PATH + pageId + API_PATH_PAGE_COMPONENT, params)
      .then(function (response) {
        dispatch({type: actionTypes.CREATE_PAGE_COMPONENT_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

export function updatePageComponent(pageId, componentId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.UPDATE_PAGE_COMPONENT_REQUEST, payload: params});
    http.put(API_PATH + pageId + API_PATH_PAGE_COMPONENT + componentId, params)
      .then(function (response) {
        dispatch({type: actionTypes.UPDATE_PAGE_COMPONENT_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}

export function deletePageComponent(pageId, componentId, params, callback){
  return dispatch => {
    dispatch({type: actionTypes.DELETE_PAGE_COMPONENT_REQUEST, payload: params});
    http.delete(API_PATH + pageId + API_PATH_PAGE_COMPONENT + componentId, params)
      .then(function (response) {
        dispatch({type: actionTypes.DELETE_PAGE_COMPONENT_RESPONSE, payload: response.data});
        if(callback)callback();
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
        dispatch(errorResponse(error));
      });
  };
}
