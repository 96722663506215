// modules
import mirrorCreator from 'mirror-creator';

// axios
import http from '../http';

export const actionTypes = mirrorCreator([
  'GET_TYPES_REQUEST',
  'GET_TYPES_RESPONSE'
]);

const API_PATH = 'types/';

export function getTypes(params){
  return dispatch => {
    dispatch({type: actionTypes.GET_TYPES_REQUEST, payload: params});
    http.get(API_PATH, params)
      .then(function (response) {
        dispatch({type: actionTypes.GET_TYPES_RESPONSE, payload: response.data});
      })
      .catch(function (error) {
        if (process.env.NODE_ENV !== 'production') { console.log(error); }
      });
  };
}
