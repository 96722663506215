// modules
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes, FaEdit, FaImage } from 'react-icons/fa';

// actions
import { updatePageComponent } from '../../../actions/pages';

// components
import SettingLinkModal from '../../presentionals/home/SettingLinkModal';

// styles
import {
  ComponentDeleteButton,
  ComponentEditButton,
  ComponentPreviwButton,
  ComponentContent,
  PhotoImgBox,
  PhotoImg,
  HiddenInput
} from '../../../styles/home';

import { iconNoImagePath } from '../../../utils/assets.js.erb'

function mapStateToProps(state) {
  return {
    page: state.pages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePageComponent:(pageId, componentId, params, callback) => dispatch(updatePageComponent(pageId, componentId, params, callback))
  }
}

class Photo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      settingLinkItem: null,
      settingLinkModal: false
    };
  }

  /**
   * リンク設定用モーダルToggle
   * @param {object} e
   * @type {void}
   */
  settingLinkModalToggle = (e) => {
    const currentItem = this.props.component.items.find(function(element) {
      const currentItemId = e ? e.currentTarget.getAttribute('data-item-id') : null;
      return element.id == currentItemId;
    });
    this.setState({
      settingLinkModal: !this.state.settingLinkModal,
      settingLinkItem: currentItem
    });
  }

  /**
   * 画像変更handle
   * @param {object} e
   * @type  {void}
   */
  handleChangeImage = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) return;
    const itemId = e.currentTarget.getAttribute('data-item-id');
    const data = new FormData();
    data.append('component[items_attributes][id]', itemId);
    data.append('component[items_attributes][thumbnail]', file);
    this.props.updatePageComponent(this.props.page.id, this.props.component.id, data, this.props.saveChangesCallBack);
  }

  /**
   * リンク設定確定handle
   * @param {object} e
   * @type  {void}
   */
  handleSettingConfirmed = (e) => {
    const currentItem = this.state.settingLinkItem;
    const newValue = document.getElementById('item-link').value || null;
    // TODO : URL判定
    if (currentItem.link != newValue) {
      const param = {
        component: {
          items_attributes: {
            id: currentItem.id,
            link: newValue
          }
        }
      }
      this.props.updatePageComponent(this.props.page.id, this.props.component.id, param, this.props.saveChangesCallBack);
    }
    this.settingLinkModalToggle();
  }

  render() {
    const { component, handleChangeDelete } = this.props;
    const item = component.items[0];
    return (
      <Container fluid>
        <Row>
          <ComponentContent style={{padding: 0}}>
            <PhotoImgBox>
              <PhotoImg id={'no-sortable' + item.id} src={item.thumbnail || iconNoImagePath} />
              <HiddenInput
                data-item-id={item.id}
                type='file'
                accept='image/*'
                id={'photo-' + item.id}
                onChange={this.handleChangeImage} />
            </PhotoImgBox>
          </ComponentContent>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm'
          onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
        <ComponentEditButton
          data-item-id={item.id}
          size='sm'
          onClick={this.settingLinkModalToggle}>
          <FaEdit />
        </ComponentEditButton>
        <ComponentPreviwButton
          size='sm'
          onClick={ () => { document.getElementById('photo-' + item.id).click() } }>
          <FaImage />
        </ComponentPreviwButton>
        <SettingLinkModal
          isOpen={this.state.settingLinkModal}
          toggle={this.settingLinkModalToggle}
          handleSettingConfirmed={this.handleSettingConfirmed}
          settingLinkItem={this.state.settingLinkItem}/>
      </Container>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Photo);
