// actions
import {actionTypes} from '../actions/users';

export const initialState = [];

export default function reducer(state = [], action) {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_PICKUPS_RESPONSE:
      return Object.assign([], state, action.payload);
    default:
      return state;
  }
}
