// modules
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes, FaEdit, FaEye } from 'react-icons/fa';
import Iframe from 'react-iframe';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';

// styles
import {
  ComponentDeleteButton,
  ComponentEditButton,
  ComponentPreviwButton,
  ComponentContent,
  ComponentIconFacebook,
  ComponentDatasourceLabel,
  ComponentDatasourceInput
} from '../../../styles/home';

class Facebook extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPreview: false,
      isLoaded: false,
      iframeWidth: 0
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ isLoaded: false });
      this.setIframeWidth();
    });
  }

  /**
   * Iframeサイズ再設定
   * @type {void}
   */
  setIframeWidth = () => {
    if (this.state.isLoaded) return;
    const iframeNode = ReactDOM.findDOMNode(this.iframe);
    const newIframeWidth = iframeNode.clientWidth - 30;
    this.setState({
      iframeWidth: newIframeWidth,
      isLoaded: true
    });
    // Facebookの仕様上500がwidthの上限なのでそれ以上は/2をmarginとして適用(センタリング)
    iframeNode.style.marginLeft = newIframeWidth > 500 ? (newIframeWidth - 500) / 2 + 'px' : '0px';
  }

  /**
   * キープレスhandle
   * @param {object} e
   * @type  {void}
   */
  handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      document.getElementById(this.props.component.id + '-data-source-input').blur();
      document.getElementById(this.props.component.id + '-preview').click();
    }
  }

  /**
   * FacebookPluginリクエストURL生成
   * @param {string}  href
   */
  createFacebookPluginsUrl(href) {
    return 'https://www.facebook.com/plugins/page.php?href='
            + href
            + '&tabs=timeline&width='
            + this.state.iframeWidth
            + '&height=400';
  }

  render() {
    const { component, handleChangeDelete, handleBlurDatasourceInput } = this.props;

    return (
      <Container fluid>
        <Row>
          <ComponentContent>
            <Row>
              <Col>
                <ComponentIconFacebook/>
              </Col>
            </Row>
            <Row>
              <Col>
                {(() => {
                  const item = component.items[0];
                  if (this.state.isPreview && item) {
                    return (
                      <Iframe
                        className='mt-2'
                        ref={ iframe => { this.iframe = iframe }}
                        url={this.createFacebookPluginsUrl(item.data_source)}
                        height='400px'
                        onLoad={this.setIframeWidth}/>
                    )
                  } else {
                    return (
                      <div>
                        <ComponentDatasourceLabel>Facebookページ名<span data-tip data-for='facebook-help'>？</span></ComponentDatasourceLabel>
                        <ReactTooltip id='facebook-help' type='warning'>
                          <p style={{textAlign: 'center', padding: 0, margin: 0}}>
                            FacebookのURLの「https://www.facebook.com/」以降の英数字です。
                          </p>
                          <p style={{textAlign: 'center', padding: 0, margin: 0}}>
                            https://www.facebook.com/tigetnet の場合は「tigetnet」がFacebookページ名です
                          </p>
                        </ReactTooltip>
                        <ComponentDatasourceInput
                          id={component.id + '-data-source-input'}
                          data-component-id={component.id}
                          data-item-id={item.id}
                          type='text'
                          onBlur={handleBlurDatasourceInput}
                          onKeyPress={this.handleOnKeyPress}
                          defaultValue={item.data_source}
                          placeholder='ページ名を入力してください'/>
                      </div>
                    )
                  }
                })()}
              </Col>
            </Row>
          </ComponentContent>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm' onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
        <ComponentEditButton
          size='sm'
          onClick={ () => { this.setState({isPreview : false}) } }>
          <FaEdit />
        </ComponentEditButton>
        <ComponentPreviwButton
          id={component.id + '-preview'}
          size='sm' onClick={ () => { this.setState({isPreview : true, isLoaded: false}) } }>
          <FaEye />
        </ComponentPreviwButton>
      </Container>
    );
  }
}

export default Facebook;
