// modules
import styled from 'styled-components';
import { Col } from 'reactstrap';

// styles
import { appColors } from './app';

export const TopBackground = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 60px;

  @media screen and (min-width:768px) {
    height: 800px;
    & > img {
      height: 400px;
      margin-top: 100px;
    }
  }

  @media screen and (max-width: 767px) {
    height: 600px;
    & > img {
      height: 360px;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 320px) {
    height: 480px;
    & > img {
      height: 280px;
      margin-top: 10px;
    }
  }
`;

export const TopPanel = styled(Col)`
  background: white;
  margin-top: -180px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
`;

export const TopImage = styled.img`

  @media screen and (min-width:768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 460px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 260px;
    object-fit: cover;
    object-position: 0 0;
  }
`;

export const LoginFormBox = styled(Col)`
  height: 360px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;

  & > form {
    width: 80%;
  }

  & > form > label {
    font-weight: bold;
    color: ${appColors.main};
    margin-bottom: 20px;
  }

  & > form > button {
    width: 80%;
    margin-left: 10%;
    border-radius: 0;
    background: ${appColors.main};
    margin-top: 10px;
    border: thin solid ${appColors.main};

    &:hover {
      background: white;
      color: ${appColors.main};
      border: thin solid ${appColors.main};
    }
  }

  & > form > a {
    font-size: 12px;
    margin:10px auto;
    display:block;
    width:100%;
    text-align: center;
    cursor: pointer;
    &:not([href]) {
      color: ${appColors.accent};
    }
  }
`;

export const NotificationLabel = styled.p`
  word-wrap: break-word;
  white-space: normal;
  color: ${appColors.main};
`

export const PickupPanel = styled(Col)`
  margin-top: 40px;
  text-align: center;

  & > .pickup-caption {
    color: ${appColors.main};
    font-size: 24px;
    font-weight: bold;
    margin: 60px 0;
  }
`;

export const PickupElement = styled(Col)`
  margin-top: 20px;
  cursor: pointer;
  & > img {
    height: 150px;
    width: 150px;
    @media screen and (max-width: 320px) {
      height: 120px;
      width: 120px;
    }
    border-radius: 50%;
    border: 3px solid white;
    object-fit: cover;
  }

  & > .pickup-title {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }

  & > .pickup-sub-title {
    margin: 0;
    color: ${appColors.main};
    font-size: 12px;
  }
`;
