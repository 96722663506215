// modules
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Redirect }    from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { notify } from 'react-notify-toast';

// actions
import { updatePassword } from '../../actions/account';
import { clearError } from '../../actions/error';

// components
import Header from '../containers/Header';
import Footer from '../presentionals/Footer';
import UpdatePasswordForm from '../presentionals/resetPassword/UpdatePasswordForm';

function mapStateToProps(state) {
  return {
    account: state.account,
    error: state.error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearError:() => dispatch(clearError()),
    updatePassword:(params, callback) => dispatch(updatePassword(params, callback))
  };
}

class ResetPassword extends React.Component {

  componentDidUpdate(prevProps, prevState){
    const prevError = prevProps.error;
    const currentError = this.props.error;
    if (prevError.statusCode !== currentError.statusCode) {
      switch(currentError.statusCode){
        case 0:
          break;
        default:
          notify.show('パスワード更新有効期限切れの可能性があります。再度パスワード再発行申請を行ってください。', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
          break;
      }
    }
  }

  /**
   * パスワード更新Submit用handle
   * @type {void}
   */
  handleSubmit = (values) => {
    if (values.password !== values.password_confirmation) {
      notify.show('パスワードと確認用パスワードが一致しません', 'custom', 2000, { background: '#FF0000B3', text: '#fff' });
      return;
    }
    const callback = () => {
      this.props.clearError();
    }
    this.props.updatePassword(values, callback);
  }

  render() {
    const { account, error } = this.props;
    if(account.id){
      return <Redirect to={'/home'} />
    } else {
      const params = new URLSearchParams(this.props.location.search);
      const reset_token = params.get('token');
      if(reset_token == null){
        return <Redirect to={'/'} />
      } else {
        return(
          <div>
            <div className='content'>
              <Header history={this.props.history}/>
              <Container>
                <Row>
                  <Col xs='12'>
                    <UpdatePasswordForm
                      onSubmit={this.handleSubmit}
                      token={reset_token}/>
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer/>
          </div>
        )
      }
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
