// modules
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaTimes, FaEdit, FaEye } from 'react-icons/fa';
import { Timeline } from 'react-twitter-widgets';
import ReactDOM from 'react-dom';

// styles
import {
  ComponentDeleteButton,
  ComponentEditButton,
  ComponentPreviwButton,
  ComponentContent,
  ComponentIconTwitter,
  ComponentDatasourceLabel,
  ComponentDatasourceInput
} from '../../../styles/home';

class Twitter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPreview: false,
      iframeWidth: null
    };
  }

  /**
   * キープレスhandle
   * @param {object} e
   * @type  {void}
   */
  handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      document.getElementById(this.props.component.id + '-data-source-input').blur();
      document.getElementById(this.props.component.id + '-preview').click();
    }
  }

  /**
   * Iframeサイズ再設定
   * @type {void}
   */
  setIframeWidth = () => {
    const iframeNode = ReactDOM.findDOMNode(this.iframe);
    this.setState({
      iframeWidth: iframeNode.clientWidth
    });
  }

  render() {
    const { component, handleChangeDelete, handleBlurDatasourceInput } = this.props;

    return (
      <Container fluid>
        <Row>
          <ComponentContent>
            <Row>
              <Col>
                <ComponentIconTwitter/>
              </Col>
            </Row>
            <Row>
              <Col>
                {(() => {
                  const item = component.items[0];
                  if (this.state.isPreview) {
                    return (
                      <Timeline
                        ref={ iframe => { this.iframe = iframe }}
                        dataSource={{
                          url: item.data_source // ユーザー名 or url
                        }}
                        options={{
                          height: '420',
                          width: this.state.iframeWidth,
                          lang: 'ja'
                        }}
                        onLoad={this.setIframeWidth}
                      />
                    )
                  } else {
                    return (
                      <div>
                        <ComponentDatasourceLabel>Twitterユーザー名</ComponentDatasourceLabel>
                        <ComponentDatasourceInput
                          id={component.id + '-data-source-input'}
                          data-component-id={component.id}
                          data-item-id={item.id}
                          type='text'
                          onBlur={handleBlurDatasourceInput}
                          onKeyPress={this.handleOnKeyPress}
                          defaultValue={item.data_source}
                          placeholder='@なしで入力してください'/>
                      </div>
                    )
                  }
                })()}
              </Col>
            </Row>
          </ComponentContent>
        </Row>
        <ComponentDeleteButton
          data-component-id={component.id}
          size='sm' onClick={handleChangeDelete}>
          <FaTimes />
        </ComponentDeleteButton>
        <ComponentEditButton
          size='sm' onClick={ () => { this.setState({isPreview : false}) } }>
          <FaEdit />
        </ComponentEditButton>
        <ComponentPreviwButton
          id={component.id + '-preview'}
          size='sm'
          onClick={ () => { this.setState({isPreview : true}) } }>
          <FaEye />
        </ComponentPreviwButton>
      </Container>
    );
  }
}

export default Twitter;
