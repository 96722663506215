// modules
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

class TextField extends React.Component {
  render() {
    const { input, type, placeholder, meta: { touched, error } } = this.props;
    return(
      <FormGroup>
        <Input {...input} type={type} placeholder={placeholder} />
        {touched && error && <span className='text-danger'>{error}</span>}
      </FormGroup>
    );
  }
}

export default TextField;
