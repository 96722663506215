// modules
import React from 'react';
import {
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  ModalAppButton,
  ModalCancelButton
} from '../../../styles/app';
import {
  NotificationLabel
} from '../../../styles/top';

class ResetPasswordModal extends React.Component {

  render() {
    const {
      handleSubmitResetPassword,
      isOpen,
      toggle,
      sendMailMessage,
      invalidMail
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>パスワード再設定申請</ModalHeader>
        <ModalBody>
          <Label>TIGET plusに登録しているメールアドレスを入力してください</Label>
          <Input
            id='reset-password-email'
            type='text'
            placeholder='example@cms.tiget.net'/>
          {(() => {
            if (sendMailMessage) {
              return(
                <div>
                  <NotificationLabel>
                    {sendMailMessage}宛に<br/>
                    パスワード再設定用のメールを送信しました。<br/>
                    メールに記載されているURLをクリックし<br/>
                    パスワードを再設定してください。
                  </NotificationLabel>
                </div>
              )
            } else if (invalidMail) {
              return(
                <div>
                  <NotificationLabel>
                    メールアドレスの形式に誤りがあります。
                  </NotificationLabel>
                </div>
              )
            }
          })()}
        </ModalBody>
        <ModalFooter>
          <ModalAppButton onClick={handleSubmitResetPassword}>送信</ModalAppButton>
          <ModalCancelButton onClick={toggle}>キャンセル</ModalCancelButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ResetPasswordModal;
