// modules
import React from 'react';
import compose from 'recompose/compose';
import { reduxForm, Field } from 'redux-form';
import { Row, Col, Label } from 'reactstrap';

// components
import TextField from '../../presentionals/top/TextField';

import {
  UpdatePasswordFormBox,
  ResetPasswordPanel
} from '../../../styles/resetPassword';

// utils
import { password } from '../../../utils/validate';

class UpdatePasswordForm extends React.Component {

  componentDidMount(){
    this.props.change("reset_password_token", this.props.token)
  }

  render() {
    const {handleSubmit} = this.props;
    return(
      <Row>
        <ResetPasswordPanel>
          <Row>
            <UpdatePasswordFormBox xs='12' sm={{ size: 6, offset: 3 }}>
              <form onSubmit={handleSubmit}>
                <Label>パスワード再設定</Label>
                <Field
                  name='password'
                  type='password'
                  component={TextField}
                  placeholder='新しいパスワード'
                  validate={[password]}/>
                <Field
                  name='password_confirmation'
                  type='password'
                  component={TextField}
                  placeholder='新しいパスワード(確認)'/>
                <Field
                  name="reset_password_token"
                  component={TextField}
                  type="hidden"/>
              <button type='submit' className='btn btn-primary'>送信</button>
                <br/>
              </form>
            </UpdatePasswordFormBox>
          </Row>
        </ResetPasswordPanel>
      </Row>
    );
  }
}

export default compose(
    reduxForm({form: 'updatePasswordForm'})
)(UpdatePasswordForm);
