// modules
import styled from 'styled-components';
import { Col } from 'reactstrap';

// styles
import { appColors } from './app';

export const StyledPolicy = styled.div`
  margin: 60px 10px 40px 10px;
  font-size: 14px;
  background: white;
  padding: 40px 10%;
  border-radius: 5px;
  width: 100%;

  & > h2 {
    font-size: 22px;
    font-weight: bold;
    border-bottom: solid 1px ${appColors.main};
    padding-bottom: 40px;
    margin-bottom: 60px;
    text-align: center;
  }

  & > h3 {
    color: black;
    font-size: 16px;
    font-weight: bold;
    border-bottom: solid 1px ${appColors.accent};
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  & > ol {
    padding-left: 20px;
    padding-bottom: 10px;
  }

  & > ol > li > ol {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    & > li {
      list-style-type:none;
      list-style-position:inside;
      counter-increment: cnt;
      &:before {
        display: marker;
        content: "(" counter(cnt) ") ";
      }
    }
  }

  & > .bottom-block > p {
    margin: 0;
  }
`;
